import React, { useEffect } from 'react';
import { Grid, FormControl, TextField, Autocomplete } from '@mui/material';
import Error from '../common/Error';
import LoadingMachineryType from '../../interfaces/LoadingMachineryType';
import LoadingMachineryTypeValidation from './LoadingMachineryTypeValidation';
import { RentalCompany } from '../../interfaces/RentalCompany';

interface AddLoadingMachineryTypeProps {
    loadingMachineryType: LoadingMachineryType;
    onChange: any;
    showErrorMessage?: boolean;
    errorMessages: string[];
    rentalCompanies: RentalCompany[];
}

const AddLoadingMachineryType = (props: AddLoadingMachineryTypeProps): JSX.Element => {
    const { loadingMachineryType, onChange, showErrorMessage, errorMessages, rentalCompanies } = props;

    const [inputValue, setInputValue] = React.useState("");

    const ATTRIBUTES = {
        NAME: 'Name'
    };

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if (!showErrorMessage || !attribute) {
            return false;
        }
        switch (attribute) {
            case ATTRIBUTES.NAME: {
                isValid = LoadingMachineryTypeValidation.validateRequiredString(loadingMachineryType.Name) && LoadingMachineryTypeValidation.validateMaximumStringLength(loadingMachineryType.Name, { maxLength: 50 });;
                break
            }
        }
        return !isValid;
    }

    useEffect(() => {
        setInputValue(loadingMachineryType?.RentalCompany?.Name ?? '')
    }, [loadingMachineryType, rentalCompanies])

    return (<Grid container>
        <FormControl fullWidth>
            <TextField
                sx={{ marginTop: '10px' }}
                label={"Nume"}
                value={loadingMachineryType.Name || ''}
                onChange={(e: React.ChangeEvent<any>) => onChange('Name', e.target.value)}
                required
                error={setValidationState(ATTRIBUTES.NAME)}
            />
            <Autocomplete
                sx={{ marginTop: '10px' }}
                options={rentalCompanies}
                getOptionLabel={(option) => option?.Name || ""}
                value={loadingMachineryType?.RentalCompany || null}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    if (!newInputValue) {
                        onChange('RentalCompany', null);
                    }
                }}
                onChange={(e, value) => {
                    onChange('RentalCompany', value)
                }}
                renderInput={(params) => <TextField {...params} label="Companie de inchiriat" />}
            />
        </FormControl>
        {showErrorMessage &&
            <Error messages={errorMessages}></Error>
        }
    </Grid>)
}

export default AddLoadingMachineryType;