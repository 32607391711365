import Material from "../../interfaces/Material";
import React from 'react';
import { Grid, FormControl, TextField } from '@mui/material';
import Error from '../common/Error';
import MaterialValidation from "./MaterialValidation";

interface AddMaterialProps {
    material: Material;
    onChange: any;
    showErrorMessage?: boolean;
    errorMessages: string[];
}

const AddMaterial = (props: AddMaterialProps): JSX.Element => {
    const { material, onChange, showErrorMessage, errorMessages } = props;

    const ATTRIBUTES = {
        NAME: 'Name'
    };

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if (!showErrorMessage || !attribute) {
            return false;
        }
        switch (attribute) {
            case ATTRIBUTES.NAME: {
                isValid = MaterialValidation.validateRequiredString(material.Name) && MaterialValidation.validateMaximumStringLength(material.Name, { maxLength: 50 });;
                break
            }
        }
        return !isValid;
    }

    return (<Grid container>
        <FormControl fullWidth>
            <TextField
                sx={{ marginTop: '10px' }}
                label={"Nume"}
                value={material.Name || ''}
                onChange={(e: React.ChangeEvent<any>) => onChange('Name', e.target.value)}
                required
                error={setValidationState(ATTRIBUTES.NAME)}
            />
        </FormControl>
        { showErrorMessage &&
            <Error messages={errorMessages}></Error>
        }
    </Grid>)
}

export default AddMaterial;