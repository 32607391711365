import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, useHistory } from 'react-router-dom';
import { Assessment, Business, Engineering, ExpandLess, ExpandMore, HomeWork, Landscape, LocalShipping, PrecisionManufacturing, SyncAlt, TableRows } from '@mui/icons-material';
import { Avatar, Button, Collapse, createTheme, ThemeProvider } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import Modal from './common/Modal';
import ValidationModel from '../services/ValidationModel';
import ResetPasswordValidation from './login/ResetPasswordValidation';
import { PasswordReset } from '../interfaces/User';
import apiService from '../services/ApiService';
import ResetPassword from './login/ResetPassword';
import TooltipButton from './common/TooltipButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faWindowClose } from '@fortawesome/free-regular-svg-icons';
import localStorageUtils from '../browser/localStorageUtils';
import localStorageConstants from '../browser/localStorageConstants';
import logo from '../icon.png';
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const listItemTheme = createTheme({
    components: {
      MuiListItem: {
        styleOverrides: {
            root: {
                paddingLeft: 0,
                paddingRight: 0
            }
        }
      }
    }
  });

export default function LeftSideBar(props: any): JSX.Element {
    const theme = useTheme();
    const history = useHistory();
    const [open, setOpen] = React.useState(true);
    const [openReferenceData, setOpenReferenceData] = React.useState(true);
    const [openReports, setOpenReports] = React.useState(true);
    const [passwordReset, setPasswordReset] = React.useState<PasswordReset>({} as PasswordReset);
    const [isResetPasswordOpen, setIsResetPasswordOpen] = React.useState(false);
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([] as string[]);
    const { loggedIn, setLoggedIn } = props;

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClickReferenceData = () => {
        setOpenReferenceData(!openReferenceData);
    };

    const handleClickReports = () => {
        setOpenReports(!openReports);
    };

    const openResetPassword = () => {
        setIsResetPasswordOpen(!isResetPasswordOpen);
        setShowErrorMessage(false);
        setErrorMessages([]);
        setPasswordReset({} as PasswordReset);
    }

    const closeResetPassword = () => {
        setIsResetPasswordOpen(false);
    }

    const onPasswordResetChange = (attribute: string, value: any) => {
        const updatedPasswordReset = { ...passwordReset } as PasswordReset;
        (updatedPasswordReset as any)[attribute] = value;
        setPasswordReset(updatedPasswordReset);
    }

    const resetPassword = () => {
        const validation: ValidationModel = ResetPasswordValidation.validatePasswordReset(passwordReset);
        if (validation.isValid) {
            setShowErrorMessage(false);
            setErrorMessages([]);
        } else {
            setShowErrorMessage(true);
            setErrorMessages(validation.validationMessages);
            return;
        }

        apiService.resetPassword(passwordReset).then(() => {
            setIsResetPasswordOpen(false);
        }).catch((reason: any) => {
            setShowErrorMessage(true);
            setErrorMessages([reason.response.data]);
        });
    }

    const logout = () => {
        localStorageUtils.clearItem(localStorageConstants.AUTH_TOKEN);
        setLoggedIn(false);
        history.push('/');
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar style={{ background: 'white' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon sx={{ color: 'black'}} />
                    </IconButton>
                    <Typography sx={{ color: 'black'}} variant="h6" noWrap component="div">
                        Load Truck
                    </Typography>
                    {
                        loggedIn &&
                        <Box sx={{ marginLeft: 'auto' }}>
                            <TooltipButton title='Reseteaza parola'>
                                <Button sx={{ mr: 2 }} variant="contained" onClick={openResetPassword} startIcon={<FontAwesomeIcon icon={faUser} />}>
                                    Reseteaza parola
                                </Button>
                            </TooltipButton>
                            <TooltipButton title='Delogheaza-te'>
                                <Button variant="contained" onClick={logout} startIcon={<FontAwesomeIcon icon={faWindowClose} />}>
                                    Delogheaza-te
                                </Button>
                            </TooltipButton>
                        </Box>
                    }
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        background: '#0080D1'
                    }
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <Avatar src={logo} sx={{background: '#0080D1', height: 35, width: 180}} variant="square"/>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon sx={{ color: 'white' }} /> : <ChevronRightIcon sx={{ color: 'white' }} />}
                    </IconButton>

                </DrawerHeader>
                <Divider sx={{ backgroundColor: 'white' }} />
                <List>
                    <ListItemButton onClick={handleClickReferenceData}>
                        <ListItemIcon sx={{ color: 'white' }}>
                            <TableRows />
                        </ListItemIcon>
                        <ListItemText sx={{ color: 'white' }} primary="Date de referinta" />
                        {openReferenceData ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                    </ListItemButton>
                    <Collapse in={openReferenceData} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ThemeProvider theme={listItemTheme}>
                            <ListItem key={"loadingMachineryType"}>
                                <NavLink activeStyle={{ backgroundColor: '#454551' }} style={{ color:'white', textDecorationLine: 'none', width: drawerWidth, paddingLeft: 32}} to={'./loadingMachineryType'}>
                                    <ListItemButton>
                                        <ListItemIcon sx={{ color: 'white' }}>
                                            <PrecisionManufacturing />
                                        </ListItemIcon>
                                        <ListItemText primary={"Utilaje"} />
                                    </ListItemButton>
                                </NavLink>
                            </ListItem>
                            </ThemeProvider>
                            <ThemeProvider theme={listItemTheme}>
                            <ListItem key={"material"}>
                                <NavLink activeStyle={{ backgroundColor: '#454551' }} style={{ color:'white', textDecorationLine: 'none', width: drawerWidth, paddingLeft: 32}} to={'./material'}>
                                    <ListItemButton>
                                        <ListItemIcon sx={{ color: 'white' }}>
                                            <Landscape />
                                        </ListItemIcon>
                                        <ListItemText primary={"Materiale"} />
                                    </ListItemButton>
                                </NavLink>
                            </ListItem>
                            </ThemeProvider>
                            <ThemeProvider theme={listItemTheme}>
                            <ListItem key={"rentalCompany"}>
                                <NavLink activeStyle={{ backgroundColor: '#454551' }} style={{ color:'white', textDecorationLine: 'none', width: drawerWidth, paddingLeft: 32}} to={'./rentalCompany'}>
                                    <ListItemButton>
                                        <ListItemIcon sx={{ color: 'white' }}>
                                            <Business />
                                        </ListItemIcon>
                                        <ListItemText primary={"Firme de inchiriere"} />
                                    </ListItemButton>
                                </NavLink>
                            </ListItem>
                            </ThemeProvider>
                            <ThemeProvider theme={listItemTheme}>
                            <ListItem key={"site"}>
                                <NavLink activeStyle={{ backgroundColor: '#454551' }} style={{ color:'white', textDecorationLine: 'none', width: drawerWidth, paddingLeft: 32}} to={'./site'}>
                                    <ListItemButton>
                                        <ListItemIcon sx={{ color: 'white' }}>
                                            <HomeWork />
                                        </ListItemIcon>
                                        <ListItemText primary={"Santiere"} />
                                    </ListItemButton>
                                </NavLink>
                            </ListItem>
                            </ThemeProvider>
                            <ThemeProvider theme={listItemTheme}>
                            <ListItem key={"transportMachinery"}>
                                <NavLink activeStyle={{ backgroundColor: '#454551' }} style={{ color:'white', textDecorationLine: 'none', width: drawerWidth, paddingLeft: 32}} to={'./transportMachinery'}>
                                    <ListItemButton>
                                        <ListItemIcon sx={{ color: 'white' }}>
                                            <LocalShipping />
                                        </ListItemIcon>
                                        <ListItemText primary={"Camioane"} />
                                    </ListItemButton>
                                </NavLink>
                            </ListItem>
                            </ThemeProvider>
                            <ThemeProvider theme={listItemTheme}>
                            <ListItem key={"user"}>
                                <NavLink activeStyle={{ backgroundColor: '#454551' }} style={{ color:'white', textDecorationLine: 'none', width: drawerWidth, paddingLeft: 32}} to={'./user'}>
                                    <ListItemButton>
                                        <ListItemIcon sx={{ color: 'white' }}>
                                            <Engineering />
                                        </ListItemIcon>
                                        <ListItemText primary={"Utilizatori"} />
                                    </ListItemButton>
                                </NavLink>
                            </ListItem>
                            </ThemeProvider>
                        </List>
                    </Collapse>
                    <Divider sx={{ backgroundColor: 'white' }} />
                    <ListItemButton onClick={handleClickReports}>
                        <ListItemIcon sx={{ color: 'white' }}>
                            <BarChartIcon />
                        </ListItemIcon>
                        <ListItemText sx={{ color: 'white' }} primary="Rapoarte" />
                        {openReports ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                    </ListItemButton>
                    <Divider sx={{ backgroundColor: 'white' }} />
                    <Collapse in={openReports} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        <ThemeProvider theme={listItemTheme}>
                            <ListItem key={"loadedTransportMachinery"}>
                                <NavLink activeStyle={{ backgroundColor: '#454551' }} style={{ color:'white', textDecorationLine: 'none', width: drawerWidth, paddingLeft: 32}} to={'./loadedTransportMachinery'}>
                                    <ListItemButton>
                                        <ListItemIcon sx={{ color: 'white' }}>
                                            <Assessment />
                                        </ListItemIcon>
                                        <ListItemText primary={"Camioane incarcate"} />
                                    </ListItemButton>
                                </NavLink>
                            </ListItem>
                            </ThemeProvider>
                        </List>
                    </Collapse>
                </List>
                <Divider sx={{ backgroundColor: 'white' }} />
            </Drawer>
            <Main open={open}>
                <>
                    <DrawerHeader />
                    {
                        <>
                            {props.children}
                        </>
                    }
                </>
            </Main>
            <Modal open={isResetPasswordOpen} title='Reseteaza parola' submit={resetPassword} handleClose={closeResetPassword}><ResetPassword onChange={onPasswordResetChange} passwordReset={passwordReset} showErrorMessage={showErrorMessage} errorMessages={errorMessages} /></Modal>
        </Box>
    );
}
