import Box from '@mui/material/Box';
import { DataGrid, GridCallbackDetails, GridColDef, GridFilterModel, GridPaginationModel, GridRowIdGetter, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
interface GridProps {
    columns: GridColDef[],
    rows: any[],
    onSelectionModelChange: (rowSelectionModel: GridRowSelectionModel) => void,
    selectionModel: any,
    paginationModel: GridPaginationModel,
    onPaginationModelChange: (model: GridPaginationModel, details: GridCallbackDetails<any>) => void,
    onSortModelChange: (model: GridSortModel, details: GridCallbackDetails<any>) => void,
    onFilterModelChange: (model: GridFilterModel, details: GridCallbackDetails<"filter">) => void,
    isLoading: boolean,
    getRowId: GridRowIdGetter<any>,
    rowCount: number
}

export default function Grid(props: GridProps) {
    const { columns, rows, selectionModel, onSelectionModelChange, paginationModel, onPaginationModelChange, onSortModelChange, onFilterModelChange, isLoading, getRowId, rowCount } = props

    return (
        <Box
            component="div"
            sx={{
                overflowY: 'scroll',
                height: { xs: '500px' },
                '::-webkit-scrollbar': {
                    width: '20px',
                },
                '::-webkit-scrollbar-button': {

                },
                '::-webkit-scrollbar-thumb': {

                },
                '::-webkit-scrollbar-thumb:hover': {

                },
                '::-webkit-scrollbar-track': {

                },
                '::-webkit-scrollbar-track-piece': {

                },
                '::-webkit-scrollbar-corner': {

                },
                '::-webkit-resizer': {

                },
            }}
        >
            <DataGrid
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                        display: "none"
                    }
                }}
                rows={rows}
                rowCount={rowCount}
                columns={columns}
                pageSizeOptions={[10, 20, 50, 100]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={onPaginationModelChange}
                sortingMode="server"
                onSortModelChange={onSortModelChange}
                filterMode="server"
                onFilterModelChange={onFilterModelChange}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel.length > 1) {
                        const selectionSet = new Set(selectionModel);
                        const result = newSelectionModel.filter((s) => !selectionSet.has(s));
                        onSelectionModelChange(result);
                    }
                    else {
                        onSelectionModelChange(newSelectionModel);
                    }
                }}
                rowSelectionModel={selectionModel}
                loading={isLoading}
                getRowId={getRowId}
            />
        </Box >
    );
}
