import * as React from 'react';
import { useState } from "react";
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import { GridColDef, GridFilterModel, GridPaginationModel, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import Modal from '../common/Modal';
import Grid from '../common/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import TooltipButton from '../common/TooltipButton';
import apiService from '../../services/ApiService';
import { ODataResponse } from '../../interfaces/OData';
import Item from '../common/Item';
import AddRentalCompany from './AddRentalCompany';
import { RentalCompany } from '../../interfaces/RentalCompany';
import ValidationModel from '../../services/ValidationModel';
import RentalCompanyValidation from './RentalCompanyValidation';

const columns: GridColDef[] = [
 {
    field: 'Name',
    headerName: 'Nume',
    minWidth: 300
  }
];

export default function RentalCompanies() {
  const [rentalCompanies, setRentalCompanies] = useState<RentalCompany[]>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  } as GridPaginationModel);
  const [sortModel, setSortModel] = useState<GridSortModel>({} as GridSortModel);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({} as GridFilterModel);
  const [rowCount, setRowCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [newRentalCompany, setNewRentalCompany] = React.useState<RentalCompany>({} as RentalCompany);
  const [editedRentalCompany, setEditedRentalCompany] = React.useState<RentalCompany>({} as RentalCompany);
  const [rentalCompany, setRentalCompany] = React.useState<RentalCompany>({} as RentalCompany);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessages, setErrorMessages] = useState([] as string[]);

  const onPaginationModelChange = React.useCallback((newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel);
  }, []);

  const onSortModelChange = React.useCallback((newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  }, []);

  const onFilterModelChange = React.useCallback((newFilterModel: GridFilterModel) => {
    setFilterModel(newFilterModel);
  }, []);

  const onSetSelectionModelChange = React.useCallback((newSelectionModel: GridRowSelectionModel) => {
    const selectedRentalCompany = rentalCompanies.find(x => x.Id === newSelectionModel[0]) as RentalCompany ?? {} as RentalCompany;
    setRentalCompany(selectedRentalCompany);
    setSelectionModel(newSelectionModel);
  }, [rentalCompanies]);

  React.useEffect(() => {
    loadRentalCompanies();
  }, [paginationModel, sortModel, filterModel]);

  const openAdd = () => {
    setIsAddOpen(!isAddOpen);
    setShowErrorMessage(false);
    setErrorMessages([]);
    setNewRentalCompany({} as RentalCompany);
  }

  const closeAdd = () => {
    setIsAddOpen(false);
  }

  const openEdit = () => {
    setIsEditOpen(!isEditOpen);
    setShowErrorMessage(false);
    setErrorMessages([]);
    setEditedRentalCompany(rentalCompany);
  }

  const closeEdit = () => {
    setIsEditOpen(false);
  }

  const openDelete = () => {
    setIsDeleteOpen(!isDeleteOpen);
  }

  const closeDelete = () => {
    setIsDeleteOpen(false);
  }

  const onAddChange = (attribute: string, value: any) => {
    const addedRentalCompany = { ...newRentalCompany } as RentalCompany;
    (addedRentalCompany as any)[attribute] = value;
    setNewRentalCompany(addedRentalCompany);
  }

  const onEditChange = (attribute: string, value: any) => {
    const newRentalCompany = { ...editedRentalCompany } as RentalCompany;
    (newRentalCompany as any)[attribute] = value;
    setEditedRentalCompany(newRentalCompany);
  }

  const addRentalCompany = () => {
    const validation: ValidationModel = RentalCompanyValidation.validateRentalCompany(newRentalCompany);
    if (validation.isValid) {
      setShowErrorMessage(false);
      setErrorMessages([]);
    } else {
      setShowErrorMessage(true);
      setErrorMessages(validation.validationMessages);
      return;
    }

    apiService.addRentalCompany(newRentalCompany).then((addedRentalCompany: RentalCompany) => {
      setIsAddOpen(false);
      loadRentalCompanies();
    }).catch((reason: any) => {
      setShowErrorMessage(true);
      setErrorMessages([reason.response.data]);
    });
  }

  const editRentalCompany = () => {
    const validation: ValidationModel = RentalCompanyValidation.validateRentalCompany(editedRentalCompany);
    if (validation.isValid) {
      setShowErrorMessage(false);
      setErrorMessages([]);
    } else {
      setShowErrorMessage(true);
      setErrorMessages(validation.validationMessages);
      return;
    }

    apiService.editRentalCompany(editedRentalCompany).then((updatedRentalCompany: RentalCompany) => {
      setIsEditOpen(false);
      loadRentalCompanies();
      setRentalCompany(editedRentalCompany);
    }).catch((reason: any) => {
      setShowErrorMessage(true);
      setErrorMessages([reason.response.data]);
    });
  }

  const deleteRentalCompany = () => {
    apiService.deleteRentalCompany(rentalCompany.Id as number).then(() => {
      setIsDeleteOpen(false);
      loadRentalCompanies();
    });
  }

  const loadRentalCompanies = () => {
    setIsLoading(true);
    apiService.loadRentalCompanies(paginationModel, sortModel, filterModel).then((odataResponse: ODataResponse<RentalCompany>) => {
      setRentalCompanies(odataResponse.value);
      setRowCount(odataResponse.count);
      setIsLoading(false);
    });
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        {selectionModel.length > 0 &&
          <>
            <Item>
              <TooltipButton title='Sterge firma de inchiriere'>
                <Button variant="contained" onClick={openDelete} startIcon={<FontAwesomeIcon icon={faTrashAlt} />}>
                  Sterge
                </Button>
              </TooltipButton>
            </Item>
            <Item>
              <TooltipButton title='Modifica firma de inchiriere'>
                <Button variant="contained" onClick={openEdit} startIcon={<FontAwesomeIcon icon={faEdit} />}>
                  Modifica
                </Button>
              </TooltipButton>
            </Item>
          </>}
        <Item>
          <TooltipButton title='Adauga firma de inchiriere'>
            <Button variant="contained" onClick={openAdd} startIcon={<FontAwesomeIcon icon={faPlusSquare} />}>
              Adauga
            </Button>
          </TooltipButton>
        </Item>
      </Box>
      <Grid rows={rentalCompanies} columns={columns} selectionModel={selectionModel} onSelectionModelChange={onSetSelectionModelChange} paginationModel={paginationModel} onPaginationModelChange={onPaginationModelChange} onSortModelChange={onSortModelChange} onFilterModelChange={onFilterModelChange} isLoading={isLoading} getRowId={(row) => row.Id} rowCount={rowCount}></Grid>
      <Modal open={isAddOpen} title='Adauga firma de inchiriere' submit={addRentalCompany} handleClose={closeAdd}><AddRentalCompany onChange={onAddChange} rentalCompany={newRentalCompany} showErrorMessage={showErrorMessage} errorMessages={errorMessages} /></Modal>
      <Modal open={isEditOpen} title='Modifica firma de inchiriere' submit={editRentalCompany} handleClose={closeEdit}><AddRentalCompany onChange={onEditChange} rentalCompany={editedRentalCompany} showErrorMessage={showErrorMessage} errorMessages={errorMessages} /></Modal>
      <Modal open={isDeleteOpen} title='Sterge firma de inchiriere' submit={deleteRentalCompany} handleClose={closeDelete} submitButtonName={'Confirma'}><Typography>Esti sigur ca doresti sa stergi firma de inchiriere {rentalCompany.Name} din lista?</Typography></Modal>
    </Box>
  );
}
