import LoadingMachineryType from "../../interfaces/LoadingMachineryType";
import ValidationModel from "../../services/ValidationModel";
import ValidationUtils from "../../services/ValidationUtils";

const nameIsMandatoryMessage = "Numele utilajului este obligatoriu!";
const nameMustBeLowerThan50CharactersMessage = "Numele utilajului trebuie sa aiba maximum 50 de caractere!";

export default class LoadingMachineryTypeValidation extends ValidationUtils {
    static validateLoadingMachineryType(loadingMachineryType: LoadingMachineryType): ValidationModel {
        const validationModel = {
            validationMessages: [],
            isValid: true
        } as ValidationModel;

        const { Name } = loadingMachineryType;

        const isNameCompleted = this.validateRequiredString(Name);

        if (!isNameCompleted) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(nameIsMandatoryMessage);
        }

        const isNameLengthCorrect = this.validateMaximumStringLength(Name, { maxLength: 50 });

        if (!isNameLengthCorrect) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(nameMustBeLowerThan50CharactersMessage);
        }

        return validationModel;
    }
}