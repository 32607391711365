import { Redirect, useLocation } from "react-router-dom";
import localStorageConstants from "../../browser/localStorageConstants";
import localStorageUtils from "../../browser/localStorageUtils";

const PrivateRoute = (props: { children: React.ReactNode }): JSX.Element => {
  const { children } = props;
  const isLoggedIn: boolean = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN) !== undefined;
  const location = useLocation();

  return isLoggedIn ? (
    <>{children}</>
  ) : (
    <Redirect
      from={`${location.pathname}${location.search}`}
      to="/signIn"
    />
  )
}

export default PrivateRoute;