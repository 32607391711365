import React, { useEffect } from 'react';
import { useState } from "react";
import Box from '@mui/material/Box';
import { Button, Checkbox, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import Modal from '../common/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import TooltipButton from '../common/TooltipButton';
import apiService from '../../services/ApiService';
import Item from '../common/Item';
import { User } from '../../interfaces/User';
import AddUser from './AddUser';
import ValidationModel from '../../services/ValidationModel';
import UserValidation from './UserValidation';
import { RentalCompany, RentalCompanyDTo } from '../../interfaces/RentalCompany';
import { ODataResponse } from '../../interfaces/OData';

const columns: GridColDef[] = [
  {
    field: 'userName',
    headerName: 'Nume',
    minWidth: 300,
  },
  {
    field: 'rentalCompany',
    headerName: 'Firma de inchiriat',
    minWidth: 300,
    valueGetter: (params) => {
      return params.row.rentalCompany?.name;
    }
  },
  {
    field: 'isAdministrator',
    headerName: 'Administrator',
    minWidth: 100,
    renderCell: (params: GridRenderCellParams<Boolean>) => (
      <Checkbox
        checked={params.value}
        disabled={true}
      />
    ),
  }
];

export default function Users() {
  const [users, setUsers] = useState<User[]>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  } as GridPaginationModel);
  const [rowCount, setRowCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [newUser, setNewUser] = React.useState<User>({} as User);
  const [editedUser, setEditedUser] = React.useState<User>({} as User);
  const [user, setUser] = React.useState<User>({} as User);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessages, setErrorMessages] = useState([] as string[]);
  const [rentalCompanies, setRentalCompanies] = useState<RentalCompanyDTo[]>([]);

  const onSetSelectionModelChange = React.useCallback((newSelectionModel: GridRowSelectionModel) => {
    const selectedUser = users.find(x => x.id === newSelectionModel[0]) as User ?? {} as User;
    setUser(selectedUser);
    setSelectionModel(newSelectionModel);
  }, [users]);

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    apiService.loadAllRentalCompanies().then((odataResponse: ODataResponse<RentalCompany>) => {

      const new_list = odataResponse.value.map((element) => ({
        id: element.Id,
        name: element.Name
      }));
      setRentalCompanies(new_list);

    });
  }, [])

  const openAdd = () => {
    setIsAddOpen(!isAddOpen);
    setShowErrorMessage(false);
    setErrorMessages([]);
    setNewUser({} as User);
  }

  const closeAdd = () => {
    setIsAddOpen(false);
  }

  const openEdit = () => {
    setIsEditOpen(!isEditOpen);
    setShowErrorMessage(false);
    setErrorMessages([]);
    setEditedUser(user);
  }

  const closeEdit = () => {
    setIsEditOpen(false);
  }

  const openDelete = () => {
    setIsDeleteOpen(!isDeleteOpen);
  }

  const closeDelete = () => {
    setIsDeleteOpen(false);
  }

  const onAddChange = (attribute: string, value: any) => {
    const addedUser = { ...newUser } as User;
    (addedUser as any)[attribute] = value;
    setNewUser(addedUser);
  }

  const onEditChange = (attribute: string, value: any) => {
    const newUser = { ...editedUser } as User;
    (newUser as any)[attribute] = value;
    setEditedUser(newUser);
  }

  const addUser = () => {
    const validation: ValidationModel = UserValidation.validateUser(newUser);
    if (validation.isValid) {
      setShowErrorMessage(false);
      setErrorMessages([]);
    } else {
      setShowErrorMessage(true);
      setErrorMessages(validation.validationMessages);
      return;
    }

    apiService.addUser(newUser).then(() => {
      setIsAddOpen(false);
      loadUsers();
    }).catch((reason: any) => {
      setShowErrorMessage(true);
      setErrorMessages([reason.response.data]);
    });
  }

  const editUser = () => {
    const validation: ValidationModel = UserValidation.validateUser(editedUser);
    if (validation.isValid) {
      setShowErrorMessage(false);
      setErrorMessages([]);
    } else {
      setShowErrorMessage(true);
      setErrorMessages(validation.validationMessages);
      return;
    }

    apiService.editUser(editedUser).then(() => {
      setIsEditOpen(false);
      loadUsers();
      setUser(editedUser);
    }).catch((reason: any) => {
      setShowErrorMessage(true);
      setErrorMessages([reason.response.data]);
    });
  }

  const deleteUser = () => {
    apiService.deleteUser(user.id as number).then(() => {
      setIsDeleteOpen(false);
      loadUsers();
    });
  }

  const loadUsers = () => {
    setIsLoading(true);
    apiService.loadUsers().then((users: User[]) => {
      setUsers(users);
      setRowCount(users.length);
      setIsLoading(false);
    });
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        {selectionModel.length > 0 &&
          <>
            <Item>
              <TooltipButton title='Sterge utilizator'>
                <Button variant="contained" onClick={openDelete} startIcon={<FontAwesomeIcon icon={faTrashAlt} />}>
                  Sterge
                </Button>
              </TooltipButton>
            </Item>
            <Item>
              <TooltipButton title='Modifica utilizator'>
                <Button variant="contained" onClick={openEdit} startIcon={<FontAwesomeIcon icon={faEdit} />}>
                  Modifica
                </Button>
              </TooltipButton>
            </Item>
          </>}
        <Item>
          <TooltipButton title='Adauga utilizator'>
            <Button variant="contained" onClick={openAdd} startIcon={<FontAwesomeIcon icon={faPlusSquare} />}>
              Adauga
            </Button>
          </TooltipButton>
        </Item>
      </Box>
      <Box
        component="div"
        sx={{
          overflowY: 'scroll',
          height: { xs: '500px' },
          '::-webkit-scrollbar': {
            width: '20px',
          },
          '::-webkit-scrollbar-button': {

          },
          '::-webkit-scrollbar-thumb': {

          },
          '::-webkit-scrollbar-thumb:hover': {

          },
          '::-webkit-scrollbar-track': {

          },
          '::-webkit-scrollbar-track-piece': {

          },
          '::-webkit-scrollbar-corner': {

          },
          '::-webkit-resizer': {

          },
        }}
      >
        <DataGrid
          sx={{
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
              display: "none"
            }
          }}
          rows={users}
          rowCount={rowCount}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 20, 50, 100]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newSelectionModel) => {
            if (newSelectionModel.length > 1) {
              const selectionSet = new Set(selectionModel);
              const result = newSelectionModel.filter((s) => !selectionSet.has(s));
              onSetSelectionModelChange(result);
            }
            else {
              onSetSelectionModelChange(newSelectionModel);
            }
          }}
          rowSelectionModel={selectionModel}
          loading={isLoading}
        />
      </Box>
      <Modal open={isAddOpen} title='Adauga utilizator' submit={addUser} handleClose={closeAdd}><AddUser onChange={onAddChange} user={newUser} rentalCompanies={rentalCompanies} showErrorMessage={showErrorMessage} errorMessages={errorMessages} /></Modal>
      <Modal open={isEditOpen} title='Modifica utilizator' submit={editUser} handleClose={closeEdit}><AddUser onChange={onEditChange} user={editedUser} rentalCompanies={rentalCompanies} showErrorMessage={showErrorMessage} errorMessages={errorMessages} /></Modal>
      <Modal open={isDeleteOpen} title='Sterge utilizator' submit={deleteUser} handleClose={closeDelete} submitButtonName={'Confirma'}><Typography>Esti sigur ca doresti sa stergi utilizatorul {user.userName} din lista?</Typography></Modal>
    </Box>
  );
}
