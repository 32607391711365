import StringUtils from './StringUtils';

export interface ValidationConfig {
    required?: boolean;
    minLength?: number;
    maxLength?: number;
}
class ValidationUtils {
    static validateRequiredString(inputValue?: string, { required }: ValidationConfig = { required: true }) {
        required = required === undefined ? true : required;

        if (required && StringUtils.isNullOrWhiteSpace(inputValue)) {
            return false;
        }
        return true;
    }

    static validateMaximumStringLength(inputValue?: string, { maxLength }: ValidationConfig = { maxLength: 50 }) {
        maxLength = maxLength === undefined ? 50 : maxLength;

        return StringUtils.getLength(inputValue) <= maxLength;
    }

    static validateMinimumStringLength(inputValue?: string, { minLength }: ValidationConfig = { minLength: 0 }) {
        minLength = minLength === undefined ? 0 : minLength;

        return StringUtils.getLength(inputValue) >= minLength;
    }

    static validateLicensePlateNumber(inputValue?: string) {
        if (StringUtils.isNullOrWhiteSpace(inputValue)) {
            return false;
        }        

        const regex = new RegExp(/^B[0-9]{2,3}[A-Z]{3}$|^(?:AB|AR|AG|BC|BH|BN|BT|BV|BR|BZ|CS|CL|CJ|CT|CV|DB|DJ|GL|GR|GJ|HR|HD|IL|IS|IF|MM|MH|MS|NT|OT|PH|SM|SJ|SB|SV|TR|TM|TL|VS|VL|VN)[0-9]{2}[A-Z]{3}$/g);

        const matchResult = inputValue!.match(regex);

        if (!matchResult) {
            return false;
        }
        
        return true;
    }
}

export default ValidationUtils;