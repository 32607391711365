import Tooltip from "@mui/material/Tooltip"

interface TooltipButtonProps {
    title: string,
    children: any
}
export default function TooltipButton(props: TooltipButtonProps) {
    const { title, children } = props
    return (
        <Tooltip title={title}
            componentsProps={{
                tooltip:
                {
                    sx:
                    {
                        fontSize: '1rem',
                        backgroundColor: 'white',
                        color: 'rgba(0,0,0,87)',
                        boxShadow: 3
                    }
                }
            }}
        >
            {children}
        </Tooltip>
    )
}
