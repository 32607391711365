import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

interface ErrorProps {
    messages: string[] | undefined,
}

export default function Error(props: ErrorProps) {
    const { messages } = props;
    return (
        <Stack sx={{ width: '100%', paddingTop:'20px' }} spacing={2}>
            <Alert severity="error">                
                {messages && messages.map(message => <>{message}<br/></>)}
            </Alert>
        </Stack>
    );
}