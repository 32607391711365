import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import apiService from '../../services/ApiService';
import { AuthenticateUser } from '../../interfaces/User';
import Error from '../common/Error';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      Load Truck
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

interface SignInProps {
  setLoggedIn: any;
}

const theme = createTheme();

const SignIn = (props: SignInProps): JSX.Element => {
  const { setLoggedIn } = props;
  const history = useHistory();
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const user = data.get('user')?.toString();
    const password = data.get('password')?.toString();
    if (user && password) {
      const authenticateUser: AuthenticateUser = {
        userName: user,
        password: password
      };
      apiService.signIn(authenticateUser).then((isSuccessful) => {
        if (isSuccessful) {
          setShowErrorMessage(false);
          setLoggedIn(true);
          history.push('/');
        } else {
          setShowErrorMessage(true);
        }
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Autentificare
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="user"
              label="Nume utilizator"
              name="user"
              autoComplete="user"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Parola"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {showErrorMessage &&
              <Error messages={[ 'Utilizator invalid!' ]} />
            }
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Autentifica-te
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

export default SignIn;