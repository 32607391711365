import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface ModalProps {
    open: boolean,
    title: string,
    submit: any,
    handleClose: any,
    children: any,
    submitButtonName?: string
}

export default function Modal(props: ModalProps) {
    const { open, title, submit, handleClose, children, submitButtonName } = props;
    return (
        <div>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{ style: {
                    minHeight: '20%',
                    maxHeight: '90%',
                  }}}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleClose}>Inchide</Button>
                    <Button variant='contained' onClick={submit}>{submitButtonName || 'Salveaza'}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}