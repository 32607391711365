import * as React from 'react';
import { useState } from "react";
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridFilterModel, GridPaginationModel, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import apiService from '../../services/ApiService';
import { ODataResponse } from '../../interfaces/OData';
import LoadedTransportMachinery from '../../interfaces/LoadedTransportMachinery';
import Item from '../common/Item';
import TooltipButton from '../common/TooltipButton';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import FileSaver from 'file-saver';
import { DateTimePicker } from '@mui/x-date-pickers';
import DateUtils from '../../services/DateUtils';
import dayjs from 'dayjs';

const columns: GridColDef[] = [
  {
    field: 'TransportMachineryId',
    headerName: 'Camion',
    minWidth: 150,
    valueGetter: (params) => {
      return params.row.TransportMachinery.LicensePlateNumber;
    }
  },
  {
    field: 'LoadedAt',
    headerName: 'Incarcat la',
    minWidth: 250,
    valueGetter: (params) => {
      let date = dayjs(params.value).toDate();
      var timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
      date.setTime(date.getTime() - timeOffsetInMS);
      return DateUtils.format(date);
    }
  },
  {
    field: 'LoadedById',
    headerName: 'Incarcat de',
    minWidth: 150,
    valueGetter: (params) => {
      return params.row.LoadedBy.Name;
    }
  },
  {
    field: 'SiteId',
    headerName: 'Santier',
    minWidth: 300,
    valueGetter: (params) => {
      return params.row.Site.Name;
    }
  },
  {
    field: 'LoadingMachineryTypeId',
    headerName: 'Utilaj',
    minWidth: 250,  
    valueGetter: (params) => {
      return params.row.LoadingMachineryType.Name;
    }
  },
  {
    field: 'MaterialId',
    headerName: 'Material',
    minWidth: 150,  
    valueGetter: (params) => {
      return params.row.Material.Name;
    }
  }
];

export default function LoadedTransportMachineries() {
  const [loadedTransportMachineries, setLoadedTransportMachineries] = useState<LoadedTransportMachinery[]>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  } as GridPaginationModel);
  const [sortModel, setSortModel] = useState<GridSortModel>([{field: 'LoadedAt', sort: 'desc'}] as GridSortModel);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({} as GridFilterModel);
  const [rowCount, setRowCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);

  const onPaginationModelChange = React.useCallback((newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel);
  }, []);

  const onSortModelChange = React.useCallback((newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  }, []);

  const onFilterModelChange = React.useCallback((newFilterModel: GridFilterModel) => {
    setFilterModel(newFilterModel);
  }, []);

  React.useEffect(() => {
    loadLoadedTransportMachineries();
  }, [paginationModel, sortModel, filterModel, dateFrom, dateTo]);

  const loadLoadedTransportMachineries = () => {
    setIsLoading(true);
    apiService.loadLoadedTransportMachineries(paginationModel, sortModel, filterModel, [ 
      { 
        field: "TransportMachineryId", 
        mappedField: "TransportMachinery/LicensePlateNumber" 
      },
      { 
        field: "LoadedById", 
        mappedField: "LoadedBy/Name" 
      },
      { 
        field: "SiteId", 
        mappedField: "Site/Name" 
      },
      { 
        field: "LoadingMachineryTypeId", 
        mappedField: "LoadingMachineryType/Name" 
      },
      { 
        field: "MaterialId", 
        mappedField: "Material/Name" 
      },
    ], dateFrom, dateTo).then((odataResponse: ODataResponse<LoadedTransportMachinery>) => {
      setLoadedTransportMachineries(odataResponse.value);
      setRowCount(odataResponse.count);
      setIsLoading(false);
    });
  }

  const exportLoadedTransportMachineries = () => {
    apiService.exportLoadedTransportMachineries(sortModel, filterModel, [ 
      { 
        field: "TransportMachineryId", 
        mappedField: "TransportMachinery/LicensePlateNumber" 
      },
      { 
        field: "LoadedById", 
        mappedField: "LoadedBy/Name" 
      },
      { 
        field: "SiteId", 
        mappedField: "Site/Name" 
      },
      { 
        field: "LoadingMachineryTypeId", 
        mappedField: "LoadingMachineryType/Name" 
      },
      { 
        field: "MaterialId", 
        mappedField: "Material/Name" 
      },
    ], dateFrom, dateTo).then((excel: Blob) => {
      const date: Date = new Date();
      FileSaver.saveAs(excel, `Raport_${date.getFullYear()}-${date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}.xlsx`);
    });
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex'
        }}
      >
        <DateTimePicker
          label="De la"
          value={dateFrom}
          onChange={(newDate) => setDateFrom(newDate.$d)}
          sx={{marginBottom: 1}}
          format="YYYY-MM-DD HH:mm:ss"
          />
        <DateTimePicker
          label="Pana la"
          value={dateTo}
          onChange={(newDate) => setDateTo(newDate.$d)}
          sx={{marginLeft: 1, marginBottom: 1}}
          format="YYYY-MM-DD HH:mm:ss"/>
        <Item sx={{
          marginBottom: 1,
          marginLeft: 'auto',
          bgcolor: 'background.paper',
          borderRadius: 1}}>
          <TooltipButton title='Exporta in Excel'>
            <Button variant="contained" onClick={exportLoadedTransportMachineries} startIcon={<FontAwesomeIcon icon={faFileExcel} />}>
              Exporta
            </Button>
          </TooltipButton>
        </Item>
      </Box>
      <Box
        component="div"
        sx={{
          overflowY: 'scroll',
          height: { xs: '500px' },
          '::-webkit-scrollbar': {
            width: '20px',
          },
          '::-webkit-scrollbar-button': {

          },
          '::-webkit-scrollbar-thumb': {

          },
          '::-webkit-scrollbar-thumb:hover': {

          },
          '::-webkit-scrollbar-track': {

          },
          '::-webkit-scrollbar-track-piece': {

          },
          '::-webkit-scrollbar-corner': {

          },
          '::-webkit-resizer': {

          },
        }}
      >
        <DataGrid
          sx={{
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
              display: "none"
            }
          }}
          rows={loadedTransportMachineries}
          rowCount={rowCount}
          columns={columns}
          pageSizeOptions={[10, 20, 50, 100]}
          paginationModel={paginationModel}
          paginationMode="server"          
          initialState={{
            sorting: {
              sortModel: [{ field: 'LoadedAt', sort: 'desc' }],
            },
          }}
          onPaginationModelChange={onPaginationModelChange}
          sortingMode="server"
          onSortModelChange={onSortModelChange}
          filterMode="server"
          onFilterModelChange={onFilterModelChange}
          loading={isLoading}
          getRowId={(row) => row.Id}
        />
      </Box >
    </Box>
  );
}
