import * as React from 'react';
import { useState } from "react";
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import { GridColDef, GridFilterModel, GridPaginationModel, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import Modal from '../common/Modal';
import Material from '../../interfaces/Material';
import Grid from '../common/Grid';
import AddMaterial from './AddMaterial';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import TooltipButton from '../common/TooltipButton';
import apiService from '../../services/ApiService';
import { ODataResponse } from '../../interfaces/OData';
import Item from '../common/Item';
import MaterialValidation from './MaterialValidation';
import ValidationModel from '../../services/ValidationModel';

const columns: GridColDef[] = [
  {
    field: 'Name',
    headerName: 'Nume',
    width: 300
  }
];

export default function MaterialEntities() {
  const [materialEntities, setMaterialEntities] = useState<Material[]>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  } as GridPaginationModel);
  const [sortModel, setSortModel] = useState<GridSortModel>({} as GridSortModel);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({} as GridFilterModel);
  const [rowCount, setRowCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [newMaterial, setNewMaterial] = React.useState<Material>({} as Material);
  const [editedMaterial, setEditedMaterial] = React.useState<Material>({} as Material);
  const [material, setMaterial] = React.useState<Material>({} as Material);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessages, setErrorMessages] = useState([] as string[]);

  const onPaginationModelChange = React.useCallback((newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel);
  }, []);

  const onSortModelChange = React.useCallback((newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  }, []);

  const onFilterModelChange = React.useCallback((newFilterModel: GridFilterModel) => {
    setFilterModel(newFilterModel);
  }, []);

  const onSetSelectionModelChange = React.useCallback((newSelectionModel: GridRowSelectionModel) => {
    const selectedMaterial = materialEntities.find(x => x.Id === newSelectionModel[0]) as Material ?? {} as Material;
    setMaterial(selectedMaterial);
    setSelectionModel(newSelectionModel);
  }, [materialEntities]);

  React.useEffect(() => {
    loadMaterialEntities();
  }, [paginationModel, sortModel, filterModel]);

  const openAdd = () => {
    setIsAddOpen(!isAddOpen);
    setShowErrorMessage(false);
    setErrorMessages([]);
    setNewMaterial({} as Material);
  }

  const closeAdd = () => {
    setIsAddOpen(false);
  }

  const openEdit = () => {
    setIsEditOpen(!isEditOpen);
    setShowErrorMessage(false);
    setErrorMessages([]);
    setEditedMaterial(material);
  }

  const closeEdit = () => {
    setIsEditOpen(false);
  }

  const openDelete = () => {
    setIsDeleteOpen(!isDeleteOpen);
  }

  const closeDelete = () => {
    setIsDeleteOpen(false);
  }

  const onAddChange = (attribute: string, value: any) => {
    const addedMaterial = { ...newMaterial } as Material;
    (addedMaterial as any)[attribute] = value;
    setNewMaterial(addedMaterial);
  }

  const onEditChange = (attribute: string, value: any) => {
    const newMaterial = { ...editedMaterial } as Material;
    (newMaterial as any)[attribute] = value;
    setEditedMaterial(newMaterial);
  }

  const addMaterial = () => {
    const validation: ValidationModel = MaterialValidation.validateMaterial(newMaterial);
    if (validation.isValid) {
      setShowErrorMessage(false);
      setErrorMessages([]);
    } else {
      setShowErrorMessage(true);
      setErrorMessages(validation.validationMessages);
      return;
    }

    apiService.addMaterial(newMaterial).then((addedMaterial: Material) => {
      setIsAddOpen(false);
      loadMaterialEntities();
    }).catch((reason: any) => {
      setShowErrorMessage(true);
      setErrorMessages([reason.response.data]);
    });
  }

  const editMaterial = () => {
    const validation: ValidationModel = MaterialValidation.validateMaterial(editedMaterial);
    if (validation.isValid) {
      setShowErrorMessage(false);
      setErrorMessages([]);
    } else {
      setShowErrorMessage(true);
      setErrorMessages(validation.validationMessages);
      return;
    }

    apiService.editMaterial(editedMaterial).then((updatedMaterial: Material) => {
      setIsEditOpen(false);
      loadMaterialEntities();
      setMaterial(editedMaterial);
    }).catch((reason: any) => {
      setShowErrorMessage(true);
      setErrorMessages([reason.response.data]);
    });
  }

  const deleteMaterial = () => {
    apiService.deleteMaterial(material.Id as number).then(() => {
      setIsDeleteOpen(false);
      loadMaterialEntities();
    });
  }

  const loadMaterialEntities = () => {
    setIsLoading(true);
    apiService.loadMaterial(paginationModel, sortModel, filterModel).then((odataResponse: ODataResponse<Material>) => {
      setMaterialEntities(odataResponse.value);
      setRowCount(odataResponse.count);
      setIsLoading(false);
    });
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        {selectionModel.length > 0 &&
          <>
            <Item>
              <TooltipButton title='Sterge material'>
                <Button variant="contained" onClick={openDelete} startIcon={<FontAwesomeIcon icon={faTrashAlt} />}>
                  Sterge
                </Button>
              </TooltipButton>
            </Item>
            <Item>
              <TooltipButton title='Modifica material'>
                <Button variant="contained" onClick={openEdit} startIcon={<FontAwesomeIcon icon={faEdit} />}>
                  Modifica
                </Button>
              </TooltipButton>
            </Item>
          </>}
        <Item>
          <TooltipButton title='Adauga material'>
            <Button variant="contained" onClick={openAdd} startIcon={<FontAwesomeIcon icon={faPlusSquare} />}>
              Adauga
            </Button>
          </TooltipButton>
        </Item>
      </Box>
      <Grid rows={materialEntities} columns={columns} selectionModel={selectionModel} onSelectionModelChange={onSetSelectionModelChange} paginationModel={paginationModel} onPaginationModelChange={onPaginationModelChange} onSortModelChange={onSortModelChange} onFilterModelChange={onFilterModelChange} isLoading={isLoading} getRowId={(row) => row.Id} rowCount={rowCount}></Grid>
      <Modal open={isAddOpen} title='Adauga material' submit={addMaterial} handleClose={closeAdd}><AddMaterial onChange={onAddChange} material={newMaterial} showErrorMessage={showErrorMessage} errorMessages={errorMessages} /></Modal>
      <Modal open={isEditOpen} title='Modifica material' submit={editMaterial} handleClose={closeEdit}><AddMaterial onChange={onEditChange} material={editedMaterial} showErrorMessage={showErrorMessage} errorMessages={errorMessages} /></Modal>
      <Modal open={isDeleteOpen} title='Sterge material' submit={deleteMaterial} handleClose={closeDelete} submitButtonName={'Confirma'}><Typography>Esti sigur ca doresti sa stergi materialul {material.Name} din lista?</Typography></Modal>
    </Box>
  );
}
