import { RentalCompany }from "../../interfaces/RentalCompany";
import ValidationModel from "../../services/ValidationModel";
import ValidationUtils from "../../services/ValidationUtils";

const nameIsMandatoryMessage = "Numele firmei de inchiriere este obligatoriu!";
const nameMustBeLowerThan50CharactersMessage = "Numele firmei de inchiriere trebuie sa aiba maximum 50 de caractere!";

export default class RentalCompanyValidation extends ValidationUtils {
    static validateRentalCompany(rentalCompany: RentalCompany): ValidationModel {
        const validationModel = {
            validationMessages: [],
            isValid: true
        } as ValidationModel;

        const { Name } = rentalCompany;

        const isNameCompleted = this.validateRequiredString(Name);

        if (!isNameCompleted) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(nameIsMandatoryMessage);
        }

        const isNameLengthCorrect = this.validateMaximumStringLength(Name, { maxLength: 50 });

        if (!isNameLengthCorrect) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(nameMustBeLowerThan50CharactersMessage);
        }

        return validationModel;
    }
}