import Material from "../../interfaces/Material";
import ValidationModel from "../../services/ValidationModel";
import ValidationUtils from "../../services/ValidationUtils";

const nameIsMandatoryMessage = "Numele materialului este obligatoriu!";
const nameMustBeLowerThan50CharactersMessage = "Numele materialului trebuie sa aiba maximum 50 de caractere!";

export default class MaterialValidation extends ValidationUtils {
    static validateMaterial(material: Material): ValidationModel {
        const validationModel = {
            validationMessages: [],
            isValid: true
        } as ValidationModel;

        const { Name } = material;

        const isNameCompleted = this.validateRequiredString(Name);

        if (!isNameCompleted) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(nameIsMandatoryMessage);
        }

        const isNameLengthCorrect = this.validateMaximumStringLength(Name, { maxLength: 50 });

        if (!isNameLengthCorrect) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(nameMustBeLowerThan50CharactersMessage);
        }

        return validationModel;
    }
}