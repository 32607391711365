import React from 'react';
import { Grid, FormControl, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { PasswordReset } from "../../interfaces/User";
import Error from '../common/Error';
import ResetPasswordValidation from "./ResetPasswordValidation";

interface ResetPasswordProps {
    passwordReset: PasswordReset;
    onChange: any;
    showErrorMessage?: boolean;
    errorMessages: string[];
}

const ResetPassword = (props: ResetPasswordProps): JSX.Element => {
    const { passwordReset, onChange, showErrorMessage, errorMessages } = props;

    const ATTRIBUTES = {
        OLDPASSWORD: 'oldPassword',
        NEWPASSWORD: 'newPassword',
        CONFIRMNEWPASSWORD: 'confirmNewPassword'
    };

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if (!showErrorMessage || !attribute) {
            return false;
        }
        switch (attribute) {
            case ATTRIBUTES.OLDPASSWORD: {
                isValid = ResetPasswordValidation.validateRequiredString(passwordReset.oldPassword) && ResetPasswordValidation.validateMaximumStringLength(passwordReset.oldPassword, { maxLength: 50 });
                break;
            }
            case ATTRIBUTES.NEWPASSWORD: {
                isValid = ResetPasswordValidation.validateRequiredString(passwordReset.newPassword) && ResetPasswordValidation.validateMaximumStringLength(passwordReset.newPassword, { maxLength: 50 });
                break;
            }
            case ATTRIBUTES.CONFIRMNEWPASSWORD: {
                isValid = ResetPasswordValidation.validateRequiredString(passwordReset.confirmNewPassword) && ResetPasswordValidation.validateMaximumStringLength(passwordReset.confirmNewPassword, { maxLength: 50 }) && ResetPasswordValidation.validateMinimumStringLength(passwordReset.confirmNewPassword, { minLength: 8 });
                isValid = isValid && (passwordReset.newPassword === passwordReset.confirmNewPassword);
                break;
            }
        }
        return !isValid;
    }

    return (<Grid container>
        <FormControl fullWidth>
            <TextField
                sx={{ marginTop:'10px' }}
                label={"Parola veche"}
                onChange={(e: React.ChangeEvent<any>) => onChange('oldPassword', e.target.value)}
                required
                type={'password'}
                error={setValidationState(ATTRIBUTES.OLDPASSWORD)}
            />
            <TextField
                sx={{ marginTop:'10px' }}
                label={"Parola noua"}
                onChange={(e: React.ChangeEvent<any>) => onChange('newPassword', e.target.value)}
                required
                type={'password'}
                error={setValidationState(ATTRIBUTES.NEWPASSWORD)}
            />
            <TextField
                sx={{ marginTop:'10px' }}
                label={"Confirma parola noua"}
                onChange={(e: React.ChangeEvent<any>) => onChange('confirmNewPassword', e.target.value)}
                required
                type={'password'}
                error={setValidationState(ATTRIBUTES.CONFIRMNEWPASSWORD)}
            />
        </FormControl>
        { showErrorMessage &&
            <Error messages={errorMessages}></Error>
        }
    </Grid>)
}

export default ResetPassword;