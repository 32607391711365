import { PasswordReset } from "../../interfaces/User";
import ValidationModel from "../../services/ValidationModel";
import ValidationUtils from "../../services/ValidationUtils";

const oldPasswordIsMandatoryMessage = "Parola veche este obligatorie!";
const oldPasswordMustBeLowerThan50CharactersMessage = "Parola veche trebuie sa aiba maximum 50 de caractere!";
const newPasswordIsMandatoryMessage = "Parola noua este obligatorie!";
const newPasswordMustBeLowerThan50CharactersMessage = "Parola noua trebuie sa aiba maximum 50 de caractere!";
const newPasswordMustBeBiggerThan8CharactersMessage = "Parola noua trebuie sa aiba minimum 8 caractere!";
const confirmNewPasswordIsMandatoryMessage = "Confirmarea parolei noi este obligatorie!";
const confirmNewPasswordMustBeLowerThan50CharactersMessage = "Confirmarea parolei noi trebuie sa aiba maximum 50 de caractere!";
const confirmNewPasswordMustBeBiggerThan8CharactersMessage = "Confirmarea parolei noi trebuie sa aiba minimum 8 caractere!";
const newPasswordAndConfirmNewPasswordMustBeIdentical = "Parola noua si confirmarea parolei noi trebuie sa fie identice!";

export default class PasswordResetValidation extends ValidationUtils {
    static validatePasswordReset(passwordReset: PasswordReset): ValidationModel {
        const validationModel = {
            validationMessages: [],
            isValid: true
        } as ValidationModel;

        const { oldPassword, newPassword, confirmNewPassword } = passwordReset;

        const isOldPasswordCompleted = this.validateRequiredString(oldPassword);

        if (!isOldPasswordCompleted) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(oldPasswordIsMandatoryMessage);
        }

        const isOldPasswordLengthCorrect = this.validateMaximumStringLength(oldPassword, { maxLength: 50 });

        if (!isOldPasswordLengthCorrect) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(oldPasswordMustBeLowerThan50CharactersMessage);
        }

        const isNewPasswordCompleted = this.validateRequiredString(newPassword);

        if (!isNewPasswordCompleted) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(newPasswordIsMandatoryMessage);
        }

        const isNewPasswordMaximumLengthCorrect = this.validateMaximumStringLength(newPassword, { maxLength: 50 });

        if (!isNewPasswordMaximumLengthCorrect) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(newPasswordMustBeLowerThan50CharactersMessage);
        }

        const isNewPasswordMinimumLengthCorrect = this.validateMinimumStringLength(newPassword, { minLength: 8 });

        if (!isNewPasswordMinimumLengthCorrect) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(newPasswordMustBeBiggerThan8CharactersMessage);
        }

        const isConfirmNewPasswordCompleted = this.validateRequiredString(confirmNewPassword);

        if (!isConfirmNewPasswordCompleted) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(confirmNewPasswordIsMandatoryMessage);
        }

        const isConfirmNewPasswordMaximumLengthCorrect = this.validateMaximumStringLength(confirmNewPassword, { maxLength: 50 });

        if (!isConfirmNewPasswordMaximumLengthCorrect) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(confirmNewPasswordMustBeLowerThan50CharactersMessage);
        }

        const isConfirmNewPasswordMinimumLengthCorrect = this.validateMinimumStringLength(confirmNewPassword, { minLength: 8 });

        if (!isConfirmNewPasswordMinimumLengthCorrect) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(confirmNewPasswordMustBeBiggerThan8CharactersMessage);
        }

        const areNewPasswordAndConfirmNewPasswordIdentical = newPassword === confirmNewPassword;

        if (!areNewPasswordAndConfirmNewPasswordIdentical) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(newPasswordAndConfirmNewPasswordMustBeIdentical);
        }

        return validationModel;
    }
}