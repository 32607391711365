import React, { useEffect } from 'react';
import { useState } from "react";
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import { GridColDef, GridFilterModel, GridPaginationModel, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import Modal from '../common/Modal';
import Grid from '../common/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import TooltipButton from '../common/TooltipButton';
import apiService from '../../services/ApiService';
import { ODataResponse } from '../../interfaces/OData';
import Item from '../common/Item';
import TransportMachinery from '../../interfaces/TransportMachinery';
import AddTransportMachinery from './AddTransportMachinery';
import ValidationModel from '../../services/ValidationModel';
import TransportMachineryValidation from './TransportMachineryValidation';
import { RentalCompany } from '../../interfaces/RentalCompany';

const columns: GridColDef[] = [
  {
    field: 'LicensePlateNumber',
    headerName: 'Numar de inmatriculare',
    minWidth: 200,
  },
  {
    field: 'RentalCompany',
    headerName: 'Firma de inchiriat',
    minWidth: 200,
    valueGetter: (params) => {
      return params.row.RentalCompany?.Name;
    }
  }
];

export default function TransportMachineries() {
  const [transportMachineries, setTransportMachineries] = useState<TransportMachinery[]>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  } as GridPaginationModel);
  const [sortModel, setSortModel] = useState<GridSortModel>({} as GridSortModel);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({} as GridFilterModel);
  const [rowCount, setRowCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [newTransportMachinery, setNewTransportMachinery] = React.useState<TransportMachinery>({} as TransportMachinery);
  const [editedTransportMachinery, setEditedTransportMachinery] = React.useState<TransportMachinery>({} as TransportMachinery);
  const [transportMachinery, setTransportMachinery] = React.useState<TransportMachinery>({} as TransportMachinery);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessages, setErrorMessages] = useState([] as string[]);

  const [rentalCompanies, setRentalCompanies] = useState<RentalCompany[]>([]);

  const onPaginationModelChange = React.useCallback((newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel);
  }, []);

  const onSortModelChange = React.useCallback((newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  }, []);

  const onFilterModelChange = React.useCallback((newFilterModel: GridFilterModel) => {
    setFilterModel(newFilterModel);
  }, []);

  const onSetSelectionModelChange = React.useCallback((newSelectionModel: GridRowSelectionModel) => {
    const selectedTransportMachinery = transportMachineries.find(x => x.Id === newSelectionModel[0]) as TransportMachinery ?? {} as TransportMachinery;
    setTransportMachinery(selectedTransportMachinery);
    setSelectionModel(newSelectionModel);
  }, [transportMachineries]);

  useEffect(() => {
    loadTransportMachineries();
  }, [paginationModel, sortModel, filterModel]);

  useEffect(() => {
    apiService.loadAllRentalCompanies().then((odataResponse: ODataResponse<RentalCompany>) => {
      setRentalCompanies(odataResponse.value);
    });
  }, [])

  const openAdd = () => {
    setIsAddOpen(!isAddOpen);
    setShowErrorMessage(false);
    setErrorMessages([]);
    setNewTransportMachinery({} as TransportMachinery);
  }

  const closeAdd = () => {
    setIsAddOpen(false);
  }

  const openEdit = () => {
    setIsEditOpen(!isEditOpen);
    setShowErrorMessage(false);
    setErrorMessages([]);
    setEditedTransportMachinery(transportMachinery);
  }

  const closeEdit = () => {
    setIsEditOpen(false);
  }

  const openDelete = () => {
    setIsDeleteOpen(!isDeleteOpen);
  }

  const closeDelete = () => {
    setIsDeleteOpen(false);
  }

  const onAddChange = (attribute: string, value: any) => {
    const addedTransportMachinery = { ...newTransportMachinery } as TransportMachinery;
    (addedTransportMachinery as any)[attribute] = attribute === 'LicensePlateNumber' ? value.toUpperCase() : value;
    setNewTransportMachinery(addedTransportMachinery);
  }

  const onEditChange = (attribute: string, value: any) => {
    const newTransportMachinery = { ...editedTransportMachinery } as TransportMachinery;
    (newTransportMachinery as any)[attribute] = attribute === 'LicensePlateNumber' ? value.toUpperCase() : value;
    setEditedTransportMachinery(newTransportMachinery);
  }

  const addTransportMachinery = () => {
    const validation: ValidationModel = TransportMachineryValidation.validateTransportMachinery(newTransportMachinery);
    if (validation.isValid) {
      setShowErrorMessage(false);
      setErrorMessages([]);
    } else {
      setShowErrorMessage(true);
      setErrorMessages(validation.validationMessages);
      return;
    }

    apiService.addTransportMachinery(newTransportMachinery).then((addedTransportMachinery: TransportMachinery) => {
      setIsAddOpen(false);
      loadTransportMachineries();
    }).catch((reason: any) => {
      setShowErrorMessage(true);
      setErrorMessages([reason.response.data]);
    });
  }

  const editTransportMachinery = () => {
    const validation: ValidationModel = TransportMachineryValidation.validateTransportMachinery(editedTransportMachinery);
    if (validation.isValid) {
      setShowErrorMessage(false);
      setErrorMessages([]);
    } else {
      setShowErrorMessage(true);
      setErrorMessages(validation.validationMessages);
      return;
    }

    apiService.editTransportMachinery(editedTransportMachinery).then((updatedTransportMachinery: TransportMachinery) => {
      setIsEditOpen(false);
      loadTransportMachineries();
      setTransportMachinery(editedTransportMachinery);
    }).catch((reason: any) => {
      setShowErrorMessage(true);
      setErrorMessages([reason.response.data]);
    });
  }

  const deleteTransportMachinery = () => {
    apiService.deleteTransportMachinery(transportMachinery.Id as number).then(() => {
      setIsDeleteOpen(false);
      loadTransportMachineries();
    });
  }

  const loadTransportMachineries = () => {
    setIsLoading(true);
    apiService.loadTransportMachineries(paginationModel, sortModel, filterModel, [
        { 
          field: "RentalCompany", 
          mappedField: "RentalCompany/Name" 
        }
      ]).then((odataResponse: ODataResponse<TransportMachinery>) => {
      setTransportMachineries(odataResponse.value);
      setRowCount(odataResponse.count);
      setIsLoading(false);
    });
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        {selectionModel.length > 0 &&
          <>
            <Item>
              <TooltipButton title='Sterge camion'>
                <Button variant="contained" onClick={openDelete} startIcon={<FontAwesomeIcon icon={faTrashAlt} />}>
                  Sterge
                </Button>
              </TooltipButton>
            </Item>
            <Item>
              <TooltipButton title='Modifica camion'>
                <Button variant="contained" onClick={openEdit} startIcon={<FontAwesomeIcon icon={faEdit} />}>
                  Modifica
                </Button>
              </TooltipButton>
            </Item>
          </>}
        <Item>
          <TooltipButton title='Adauga camion'>
            <Button variant="contained" onClick={openAdd} startIcon={<FontAwesomeIcon icon={faPlusSquare} />}>
              Adauga
            </Button>
          </TooltipButton>
        </Item>
      </Box>
      <Grid rows={transportMachineries} columns={columns} selectionModel={selectionModel} onSelectionModelChange={onSetSelectionModelChange} paginationModel={paginationModel} onPaginationModelChange={onPaginationModelChange} onSortModelChange={onSortModelChange} onFilterModelChange={onFilterModelChange} isLoading={isLoading} getRowId={(row) => row.Id} rowCount={rowCount}></Grid>
      <Modal open={isAddOpen} title='Adauga camion' submit={addTransportMachinery} handleClose={closeAdd}><AddTransportMachinery onChange={onAddChange} transportMachinery={newTransportMachinery} rentalCompanies={rentalCompanies} showErrorMessage={showErrorMessage} errorMessages={errorMessages} /></Modal>
      <Modal open={isEditOpen} title='Modifica camion' submit={editTransportMachinery} handleClose={closeEdit}><AddTransportMachinery onChange={onEditChange} transportMachinery={editedTransportMachinery} rentalCompanies={rentalCompanies} showErrorMessage={showErrorMessage} errorMessages={errorMessages} /></Modal>
      <Modal open={isDeleteOpen} title='Sterge camion' submit={deleteTransportMachinery} handleClose={closeDelete} submitButtonName={'Confirma'}><Typography>Esti sigur ca doresti sa stergi camionul cu numarul {transportMachinery.LicensePlateNumber} din lista?</Typography></Modal>
    </Box>
  );
}
