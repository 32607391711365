import React, { useEffect } from 'react';
import { useState } from "react";
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import { GridColDef, GridFilterModel, GridPaginationModel, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import Modal from '../common/Modal';
import Grid from '../common/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import TooltipButton from '../common/TooltipButton';
import apiService from '../../services/ApiService';
import { ODataResponse } from '../../interfaces/OData';
import Item from '../common/Item';
import AddLoadingMachineryType from './AddLoadingMachineryType';
import LoadingMachineryType from '../../interfaces/LoadingMachineryType';
import ValidationModel from '../../services/ValidationModel';
import LoadingMachineryTypeValidation from './LoadingMachineryTypeValidation';
import { RentalCompany } from '../../interfaces/RentalCompany';

const columns: GridColDef[] = [
  {
    field: 'Name',
    headerName: 'Nume',
    minWidth: 300,
  }, {
    field: 'RentalCompany',
    headerName: 'Firma de inchiriat',
    minWidth: 300,
    valueGetter: (params) => {
      return params.row.RentalCompany?.Name;
    }
  }
];

export default function LoadingMachineryTypes() {
  const [loadingMachineryTypes, setLoadingMachineryTypes] = useState<LoadingMachineryType[]>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  } as GridPaginationModel);
  const [sortModel, setSortModel] = useState<GridSortModel>({} as GridSortModel);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({} as GridFilterModel);
  const [rowCount, setRowCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [newLoadingMachineryType, setNewLoadingMachineryType] = React.useState<LoadingMachineryType>({} as LoadingMachineryType);
  const [editedLoadingMachineryType, setEditedLoadingMachineryType] = React.useState<LoadingMachineryType>({} as LoadingMachineryType);
  const [loadingMachineryType, setLoadingMachineryType] = React.useState<LoadingMachineryType>({} as LoadingMachineryType);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessages, setErrorMessages] = useState([] as string[]);

  const [rentalCompanies, setRentalCompanies] = useState<RentalCompany[]>([]);

  const onPaginationModelChange = React.useCallback((newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel);
  }, []);

  const onSortModelChange = React.useCallback((newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  }, []);

  const onFilterModelChange = React.useCallback((newFilterModel: GridFilterModel) => {
    setFilterModel(newFilterModel);
  }, []);

  const onSetSelectionModelChange = React.useCallback((newSelectionModel: GridRowSelectionModel) => {
    const selectedLoadingMachineryType = loadingMachineryTypes.find(x => x.Id === newSelectionModel[0]) as LoadingMachineryType ?? {} as LoadingMachineryType;
    setLoadingMachineryType(selectedLoadingMachineryType);
    setSelectionModel(newSelectionModel);
  }, [loadingMachineryTypes]);

  useEffect(() => {
    loadLoadingMachineryTypes();
  }, [paginationModel, sortModel, filterModel]);

  useEffect(() => {
    apiService.loadAllRentalCompanies().then((odataResponse: ODataResponse<RentalCompany>) => {
      setRentalCompanies(odataResponse.value);
    });
  }, [])

  const openAdd = () => {
    setIsAddOpen(!isAddOpen);
    setShowErrorMessage(false);
    setErrorMessages([]);
    setNewLoadingMachineryType({} as LoadingMachineryType);
  }

  const closeAdd = () => {
    setIsAddOpen(false);
  }

  const openEdit = () => {
    setIsEditOpen(!isEditOpen);
    setShowErrorMessage(false);
    setErrorMessages([]);
    setEditedLoadingMachineryType(loadingMachineryType);
  }

  const closeEdit = () => {
    setIsEditOpen(false);
  }

  const openDelete = () => {
    setIsDeleteOpen(!isDeleteOpen);
  }

  const closeDelete = () => {
    setIsDeleteOpen(false);
  }

  const onAddChange = (attribute: string, value: any) => {
    const addedLoadingMachineryType = { ...newLoadingMachineryType } as LoadingMachineryType;
    (addedLoadingMachineryType as any)[attribute] = value;
    setNewLoadingMachineryType(addedLoadingMachineryType);
  }

  const onEditChange = (attribute: string, value: any) => {
    const newLoadingMachineryType = { ...editedLoadingMachineryType } as LoadingMachineryType;
    (newLoadingMachineryType as any)[attribute] = value;
    setEditedLoadingMachineryType(newLoadingMachineryType);
  }

  const addLoadingMachineryType = () => {
    const validation: ValidationModel = LoadingMachineryTypeValidation.validateLoadingMachineryType(newLoadingMachineryType);
    if (validation.isValid) {
      setShowErrorMessage(false);
      setErrorMessages([]);
    } else {
      setShowErrorMessage(true);
      setErrorMessages(validation.validationMessages);
      return;
    }

    apiService.addLoadingMachineryType(newLoadingMachineryType).then((addedLoadingMachineryType: LoadingMachineryType) => {
      setIsAddOpen(false);
      loadLoadingMachineryTypes();
    }).catch((reason: any) => {
      setShowErrorMessage(true);
      setErrorMessages([reason.response.data]);
    });
  }

  const editLoadingMachineryType = () => {
    const validation: ValidationModel = LoadingMachineryTypeValidation.validateLoadingMachineryType(editedLoadingMachineryType);
    if (validation.isValid) {
      setShowErrorMessage(false);
      setErrorMessages([]);
    } else {
      setShowErrorMessage(true);
      setErrorMessages(validation.validationMessages);
      return;
    }

    apiService.editLoadingMachineryType(editedLoadingMachineryType).then((updatedLoadingMachineryType: LoadingMachineryType) => {
      setIsEditOpen(false);
      loadLoadingMachineryTypes();
      setLoadingMachineryType(editedLoadingMachineryType);
    }).catch((reason: any) => {
      setShowErrorMessage(true);
      setErrorMessages([reason.response.data]);
    });
  }

  const deleteLoadingMachineryType = () => {
    apiService.deleteLoadingMachineryType(loadingMachineryType.Id as number).then(() => {
      setIsDeleteOpen(false);
      loadLoadingMachineryTypes();
    });
  }

  const loadLoadingMachineryTypes = () => {
    setIsLoading(true);
    apiService.loadLoadingMachineryTypes(paginationModel, sortModel, filterModel, [
      { 
        field: "RentalCompany", 
        mappedField: "RentalCompany/Name" 
      }
    ]).then((odataResponse: ODataResponse<LoadingMachineryType>) => {
      setLoadingMachineryTypes(odataResponse.value);
      setRowCount(odataResponse.count);
      setIsLoading(false);
    });
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        {selectionModel.length > 0 &&
          <>
            <Item>
              <TooltipButton title='Sterge utilaj'>
                <Button variant="contained" onClick={openDelete} startIcon={<FontAwesomeIcon icon={faTrashAlt} />}>
                  Sterge
                </Button>
              </TooltipButton>
            </Item>
            <Item>
              <TooltipButton title='Modifica utilaj'>
                <Button variant="contained" onClick={openEdit} startIcon={<FontAwesomeIcon icon={faEdit} />}>
                  Modifica
                </Button>
              </TooltipButton>
            </Item>
          </>}
        <Item>
          <TooltipButton title='Adauga utilaj'>
            <Button variant="contained" onClick={openAdd} startIcon={<FontAwesomeIcon icon={faPlusSquare} />}>
              Adauga
            </Button>
          </TooltipButton>
        </Item>
      </Box>
      <Grid rows={loadingMachineryTypes} columns={columns} selectionModel={selectionModel} onSelectionModelChange={onSetSelectionModelChange} paginationModel={paginationModel} onPaginationModelChange={onPaginationModelChange} onSortModelChange={onSortModelChange} onFilterModelChange={onFilterModelChange} isLoading={isLoading} getRowId={(row) => row.Id} rowCount={rowCount}></Grid>
      <Modal open={isAddOpen} title='Adauga utilaj' submit={addLoadingMachineryType} handleClose={closeAdd}><AddLoadingMachineryType onChange={onAddChange} loadingMachineryType={newLoadingMachineryType} rentalCompanies={rentalCompanies} showErrorMessage={showErrorMessage} errorMessages={errorMessages} /></Modal>
      <Modal open={isEditOpen} title='Modifica utilaj' submit={editLoadingMachineryType} handleClose={closeEdit}><AddLoadingMachineryType onChange={onEditChange} loadingMachineryType={editedLoadingMachineryType} rentalCompanies={rentalCompanies} showErrorMessage={showErrorMessage} errorMessages={errorMessages} /></Modal>
      <Modal open={isDeleteOpen} title='Sterge utilaj' submit={deleteLoadingMachineryType} handleClose={closeDelete} submitButtonName={'Confirma'}><Typography>Esti sigur ca doresti sa stergi utilajul {loadingMachineryType.Name} din lista?</Typography></Modal>
    </Box>
  );
}
