import React, { useEffect } from 'react';
import { Grid, FormControl, TextField, Checkbox, FormControlLabel, Autocomplete } from '@mui/material';
import { User } from "../../interfaces/User";
import Error from '../common/Error';
import UserValidation from "./UserValidation";
import { RentalCompanyDTo } from '../../interfaces/RentalCompany';

interface AddUserProps {
    user: User;
    onChange: any;
    showErrorMessage?: boolean;
    errorMessages: string[];
    rentalCompanies: RentalCompanyDTo[];
}

const AddUser = (props: AddUserProps): JSX.Element => {
    const { user, onChange, showErrorMessage, errorMessages, rentalCompanies } = props;

    const [inputValue, setInputValue] = React.useState("");

    const [isAdministrator, setIsAdministrator] = React.useState<boolean>(false);

    const ATTRIBUTES = {
        USERNAME: 'userName',
        ISADMINISTRATOR: 'isAdministrator'
    };

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if (!showErrorMessage || !attribute) {
            return false;
        }
        switch (attribute) {
            case ATTRIBUTES.USERNAME: {
                isValid = UserValidation.validateRequiredString(user.userName) && UserValidation.validateMaximumStringLength(user.userName, { maxLength: 50 });
                break;
            }
        }
        return !isValid;
    }

    useEffect(() => {
        setIsAdministrator(user.isAdministrator);
    }, [user.isAdministrator]);

    useEffect(() => {
        setInputValue(user?.rentalCompany?.name ?? '')
    }, [user, rentalCompanies])

    return (<Grid container>
        <FormControl fullWidth>
            <TextField
                sx={{ marginTop: '10px' }}
                label={"Nume"}
                value={user.userName || ''}
                onChange={(e: React.ChangeEvent<any>) => onChange('userName', e.target.value)}
                required
                error={setValidationState(ATTRIBUTES.USERNAME)}
            />
            <Autocomplete
                sx={{ marginTop: '10px' }}
                options={rentalCompanies}
                getOptionLabel={(option) => option?.name || ""}
                value={user?.rentalCompany || null}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    if (!newInputValue) {
                        onChange('rentalCompany', null);
                    }
                }}
                onChange={(e, value) => {
                    onChange('rentalCompany', value)
                }}
                renderInput={(params) => <TextField {...params} label="Companie de inchiriat" />}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isAdministrator}
                        onChange={(e: React.ChangeEvent<any>, checked: boolean) => {
                            setIsAdministrator(checked);
                            onChange('isAdministrator', checked);
                        }}
                    />
                }
                label="Administrator" />
        </FormControl>
        {showErrorMessage &&
            <Error messages={errorMessages}></Error>
        }
    </Grid>)
}

export default AddUser;