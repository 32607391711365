import { Grid, FormControl, TextField, Autocomplete } from '@mui/material';
import TransportMachinery from "../../interfaces/TransportMachinery";
import Error from '../common/Error';

interface AllocateTransportMachineryProps {
    transportMachinery: TransportMachinery;
    onChange: any;
    showErrorMessage?: boolean;
    errorMessages: string[];
    transportMachineries: TransportMachinery[];
}

const AllocateTransportMachinery = (props: AllocateTransportMachineryProps): JSX.Element => {
    const { transportMachinery, onChange, showErrorMessage, errorMessages, transportMachineries } = props;

    return (<Grid container>
        <FormControl fullWidth>
            <Autocomplete
                options={transportMachineries}
                getOptionLabel={(option) => option?.LicensePlateNumber || ""}
                value={transportMachinery}
                onChange={(e, value) => {
                    onChange(value);
                }}
                renderInput={(params) => <TextField {...params} label="Camion" />}
            />
        </FormControl>
        {showErrorMessage &&
            <Error messages={errorMessages}></Error>
        }
    </Grid>)
}

export default AllocateTransportMachinery;