import TransportMachinery from "../../interfaces/TransportMachinery";
import ValidationModel from "../../services/ValidationModel";
import ValidationUtils from "../../services/ValidationUtils";

const licensePlateNumberIsMandatoryMessage = "Numarul de inmatriculare este obligatoriu!";
const licensePlateNumberMustBeValidMessage = "Numarul de inmatriculare trebuie sa fie valid!";

export default class TransportMachineryValidation extends ValidationUtils {
    static validateTransportMachinery(transportMachinery: TransportMachinery): ValidationModel {
        const validationModel = {
            validationMessages: [],
            isValid: true
        } as ValidationModel;

        const { LicensePlateNumber } = transportMachinery;

        const isLicensePlateNumberCompleted = this.validateRequiredString(LicensePlateNumber);

        if (!isLicensePlateNumberCompleted) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(licensePlateNumberIsMandatoryMessage);
        }

        const isLicensePlateNumberValid = this.validateLicensePlateNumber(LicensePlateNumber);

        if (!isLicensePlateNumberValid) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(licensePlateNumberMustBeValidMessage);
        }

        return validationModel;
    }
}