import React from 'react';
import { Grid, FormControl, TextField } from '@mui/material';
import Site from "../../interfaces/Site";
import Error from '../common/Error';
import SiteValidation from "./SiteValidation";

interface AddSiteProps {
    site: Site;
    onChange: any;
    showErrorMessage?: boolean;
    errorMessages: string[];
}

const AddSite = (props: AddSiteProps): JSX.Element => {
    const { site, onChange, showErrorMessage, errorMessages } = props;

    const ATTRIBUTES = {
        NAME: 'Name'
    };

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if (!showErrorMessage || !attribute) {
            return false;
        }
        switch (attribute) {
            case ATTRIBUTES.NAME: {
                isValid = SiteValidation.validateRequiredString(site.Name) && SiteValidation.validateMaximumStringLength(site.Name, { maxLength: 50 });;
                break
            }
        }
        return !isValid;
    }

    return (<Grid container>
        <FormControl fullWidth>
            <TextField
                sx={{ marginTop:'10px' }}
                label={"Nume"}                    
                value={site.Name || ''}
                onChange={(e: React.ChangeEvent<any>) => onChange('Name', e.target.value)}
                required
                error={setValidationState(ATTRIBUTES.NAME)}
            />
        </FormControl>
        { showErrorMessage &&
            <Error messages={errorMessages}></Error>
        }
    </Grid>)
}

export default AddSite;