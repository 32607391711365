import React, { useEffect } from 'react';
import { Grid, FormControl, TextField, Autocomplete } from '@mui/material';
import TransportMachinery from "../../interfaces/TransportMachinery";
import Error from '../common/Error';
import TransportMachineryValidation from "./TransportMachineryValidation";
import { RentalCompany } from '../../interfaces/RentalCompany';

interface AddTransportMachineryProps {
    transportMachinery: TransportMachinery;
    onChange: any;
    showErrorMessage?: boolean;
    errorMessages: string[];
    rentalCompanies: RentalCompany[];
}

const AddTransportMachinery = (props: AddTransportMachineryProps): JSX.Element => {
    const { transportMachinery, onChange, showErrorMessage, errorMessages, rentalCompanies } = props;

    const [inputValue, setInputValue] = React.useState("");

    const ATTRIBUTES = {
        LICENSEPLATENUMBER: 'LicensePlateNumber'
    };

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if (!showErrorMessage || !attribute) {
            return false;
        }
        switch (attribute) {
            case ATTRIBUTES.LICENSEPLATENUMBER: {
                isValid = TransportMachineryValidation.validateRequiredString(transportMachinery.LicensePlateNumber) && TransportMachineryValidation.validateLicensePlateNumber(transportMachinery.LicensePlateNumber);;
                break
            }
        }
        return !isValid;
    }

    useEffect(() => {
        setInputValue(transportMachinery?.RentalCompany?.Name ?? '')
    }, [transportMachinery, rentalCompanies])

    return (<Grid container>
        <FormControl fullWidth>
            <TextField
                sx={{ marginTop: '10px' }}
                label={"Numar de inmatriculare"}
                value={transportMachinery.LicensePlateNumber || ''}
                onChange={(e: React.ChangeEvent<any>) => onChange('LicensePlateNumber', e.target.value)}
                required
                error={setValidationState(ATTRIBUTES.LICENSEPLATENUMBER)}
            />
            <Autocomplete
                sx={{ marginTop: '10px' }}
                options={rentalCompanies}
                getOptionLabel={(option) => option?.Name || ""}
                value={transportMachinery?.RentalCompany || null}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    if (!newInputValue) {
                        onChange('RentalCompany', null);
                    }
                }}
                onChange={(e, value) => {
                    onChange('RentalCompany', value)
                }}
                renderInput={(params) => <TextField {...params} label="Companie de inchiriat" />}
            />
        </FormControl>
        {showErrorMessage &&
            <Error messages={errorMessages}></Error>
        }
    </Grid>)
}

export default AddTransportMachinery;