import Site from "../../interfaces/Site";
import TransportMachinery from "../../interfaces/TransportMachinery";
import ValidationModel from "../../services/ValidationModel";
import ValidationUtils from "../../services/ValidationUtils";

const nameIsMandatoryMessage = "Numele santierului este obligatoriu!";
const nameMustBeLowerThan50CharactersMessage = "Numele santierului trebuie sa aiba maximum 50 de caractere!";
const transportMachineryIsMandatoryMessage = "Camionul este obligatoriu!";

export default class SiteValidation extends ValidationUtils {
    static validateSite(site: Site): ValidationModel {
        const validationModel = {
            validationMessages: [],
            isValid: true
        } as ValidationModel;

        const { Name } = site;

        const isNameCompleted = this.validateRequiredString(Name);

        if (!isNameCompleted) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(nameIsMandatoryMessage);
        }

        const isNameLengthCorrect = this.validateMaximumStringLength(Name, { maxLength: 50 });

        if (!isNameLengthCorrect) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(nameMustBeLowerThan50CharactersMessage);
        }

        return validationModel;
    }

    static validateAllocation(transportMachinery: TransportMachinery): ValidationModel {
        const validationModel = {
            validationMessages: [],
            isValid: true
        } as ValidationModel;

        if (!transportMachinery.Id) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(transportMachineryIsMandatoryMessage);
        }

        return validationModel;
    }
}