import * as React from 'react';
import { useState } from "react";
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import { GridColDef, GridFilterModel, GridPaginationModel, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import Modal from '../common/Modal';
import Grid from '../common/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import TooltipButton from '../common/TooltipButton';
import apiService from '../../services/ApiService';
import { ODataResponse } from '../../interfaces/OData';
import Item from '../common/Item';
import Site from '../../interfaces/Site';
import AddSite from './AddSite';
import ValidationModel from '../../services/ValidationModel';
import SiteValidation from './SiteValidation';
import TransportMachinery from '../../interfaces/TransportMachinery';
import AllocateTransportMachinery from './AllocateTransportMachinery';
import SiteXTransportMachinery from '../../interfaces/SiteXTransportMachinery';

const columns: GridColDef[] = [
  {
    field: 'Name',
    headerName: 'Nume',
    minWidth: 300
  }
];

const transportMachineriesColumns: GridColDef[] = [
  {
    field: 'LicensePlateNumber',
    headerName: 'Numar de inmatriculare',
    minWidth: 300,
  },
  {
    field: 'RentalCompany',
    headerName: 'Firma de inchiriat',
    minWidth: 300,
    valueGetter: (params) => {
      return params.row.RentalCompany?.Name;
    }
  }
];

export default function Sites() {
  const [sites, setSites] = useState<Site[]>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  } as GridPaginationModel);
  const [sortModel, setSortModel] = useState<GridSortModel>({} as GridSortModel);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({} as GridFilterModel);
  const [rowCount, setRowCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [newSite, setNewSite] = React.useState<Site>({} as Site);
  const [editedSite, setEditedSite] = React.useState<Site>({} as Site);
  const [site, setSite] = React.useState<Site>({} as Site);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessages, setErrorMessages] = useState([] as string[]);

  const onPaginationModelChange = React.useCallback((newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel);
  }, []);

  const onSortModelChange = React.useCallback((newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  }, []);

  const onFilterModelChange = React.useCallback((newFilterModel: GridFilterModel) => {
    setFilterModel(newFilterModel);
  }, []);

  const onSetSelectionModelChange = React.useCallback((newSelectionModel: GridRowSelectionModel) => {
    const selectedSite = sites.find(x => x.Id === newSelectionModel[0]) as Site ?? {} as Site;
    setSite(selectedSite);
    if (selectedSite.Id) {
      loadTransportMachineries(selectedSite.Id!);
    } else {
      setTransportMachineries([]);
    }
    setTransportMachineryPaginationModel({
      page: 0,
      pageSize: 10,
    } as GridPaginationModel);
    setTransportMachinerySortModel({} as GridSortModel);
    setTransportMachineryFilterModel({} as GridFilterModel);
    setTransportMachinerySelectionModel([]);
    setTransportMachinery({} as TransportMachinery);
    setSelectionModel(newSelectionModel);
  }, [sites]);

  React.useEffect(() => {
    loadSites();
  }, [paginationModel, sortModel, filterModel]);

  const openAdd = () => {
    setIsAddOpen(!isAddOpen);
    setShowErrorMessage(false);
    setErrorMessages([]);
    setNewSite({} as Site);
  }

  const closeAdd = () => {
    setIsAddOpen(false);
  }

  const openEdit = () => {
    setIsEditOpen(!isEditOpen);
    setShowErrorMessage(false);
    setErrorMessages([]);
    setEditedSite(site);
  }

  const closeEdit = () => {
    setIsEditOpen(false);
  }

  const openDelete = () => {
    setIsDeleteOpen(!isDeleteOpen);
  }

  const closeDelete = () => {
    setIsDeleteOpen(false);
  }

  const onAddChange = (attribute: string, value: any) => {
    const addedSite = { ...newSite } as Site;
    (addedSite as any)[attribute] = value;
    setNewSite(addedSite);
  }

  const onEditChange = (attribute: string, value: any) => {
    const newSite = { ...editedSite } as Site;
    (newSite as any)[attribute] = value;
    setEditedSite(newSite);
  }

  const addSite = () => {
    const validation: ValidationModel = SiteValidation.validateSite(newSite);
    if (validation.isValid) {
      setShowErrorMessage(false);
      setErrorMessages([]);
    } else {
      setShowErrorMessage(true);
      setErrorMessages(validation.validationMessages);
      return;
    }

    apiService.addSite(newSite).then((addedSite: Site) => {
      setIsAddOpen(false);
      loadSites();
    }).catch((reason: any) => {
      setShowErrorMessage(true);
      setErrorMessages([reason.response.data]);
    });
  }

  const editSite = () => {
    const validation: ValidationModel = SiteValidation.validateSite(editedSite);
    if (validation.isValid) {
      setShowErrorMessage(false);
      setErrorMessages([]);
    } else {
      setShowErrorMessage(true);
      setErrorMessages(validation.validationMessages);
      return;
    }

    apiService.editSite(editedSite).then((updatedSite: Site) => {
      setIsEditOpen(false);
      loadSites();
      setSite(editedSite);
    }).catch((reason: any) => {
      setShowErrorMessage(true);
      setErrorMessages([reason.response.data]);
    });
  }

  const deleteSite = () => {
    apiService.deleteSite(site.Id as number).then(() => {
      setIsDeleteOpen(false);
      loadSites();
    });
  }

  const loadSites = () => {
    setIsLoading(true);
    apiService.loadSites(paginationModel, sortModel, filterModel).then((odataResponse: ODataResponse<Site>) => {
      setSites(odataResponse.value);
      setRowCount(odataResponse.count);
      setIsLoading(false);
    });
  }

  const [transportMachineries, setTransportMachineries] = useState<TransportMachinery[]>([]);
  const [transportMachineryPaginationModel, setTransportMachineryPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  } as GridPaginationModel);
  const [transportMachinerySortModel, setTransportMachinerySortModel] = useState<GridSortModel>({} as GridSortModel);
  const [transportMachineryFilterModel, setTransportMachineryFilterModel] = useState<GridFilterModel>({} as GridFilterModel);
  const [transportMachineryRowCount, setTransportMachineryRowCount] = useState<number>(0);
  const [isTransportMachineryLoading, setIsTransportMachineryLoading] = useState<boolean>(false);

  const [newTransportMachinery, setNewTransportMachinery] = React.useState<TransportMachinery>({} as TransportMachinery);
  const [transportMachinery, setTransportMachinery] = React.useState<TransportMachinery>({} as TransportMachinery);
  const [transportMachinerySelectionModel, setTransportMachinerySelectionModel] = useState<GridRowSelectionModel>([]);
  const [isAddTransportMachineryOpen, setIsAddTransportMachineryOpen] = useState(false);
  const [isDeleteTransportMachineryOpen, setIsDeleteTransportMachineryOpen] = useState(false);

  const [unallocatedTransportMachineries, setUnallocatedTransportMachineries] = useState<TransportMachinery[]>([]);

  const onTransportMachineryPaginationModelChange = React.useCallback((newPaginationModel: GridPaginationModel) => {
    setTransportMachineryPaginationModel(newPaginationModel);
  }, []);

  const onTransportMachinerySortModelChange = React.useCallback((newSortModel: GridSortModel) => {
    setTransportMachinerySortModel(newSortModel);
  }, []);

  const onTransportMachineryFilterModelChange = React.useCallback((newFilterModel: GridFilterModel) => {
    setTransportMachineryFilterModel(newFilterModel);
  }, []);

  const onSetTransportMachinerySelectionModelChange = React.useCallback((newSelectionModel: GridRowSelectionModel) => {
    const selectedTransportMachinery = transportMachineries.find(x => x.Id === newSelectionModel[0]) as TransportMachinery ?? {} as TransportMachinery;
    setTransportMachinery(selectedTransportMachinery);
    setTransportMachinerySelectionModel(newSelectionModel);
  }, [transportMachineries]);

  React.useEffect(() => {
    if (site.Id) {
      loadTransportMachineries(site.Id!);
    }
  }, [transportMachineryPaginationModel, transportMachinerySortModel, transportMachineryFilterModel]);

  const openAddTransportMachinery = () => {
    setIsAddTransportMachineryOpen(!isAddOpen);
    setShowErrorMessage(false);
    setErrorMessages([]);
    setNewTransportMachinery({} as TransportMachinery);
  }

  const closeAddTransportMachinery = () => {
    setIsAddTransportMachineryOpen(false);
  }

  const openDeleteTransportMachinery = () => {
    setIsDeleteTransportMachineryOpen(!isDeleteOpen);
  }

  const closeDeleteTransportMachinery = () => {
    setIsDeleteTransportMachineryOpen(false);
  }

  const onAddTransportMachineryChange = (addedTransportMachinery: TransportMachinery) => {
    setNewTransportMachinery(addedTransportMachinery);
  }

  const addTransportMachinery = () => {
    const validation: ValidationModel = SiteValidation.validateAllocation(newTransportMachinery);
    if (validation.isValid) {
      setShowErrorMessage(false);
      setErrorMessages([]);
    } else {
      setShowErrorMessage(true);
      setErrorMessages(validation.validationMessages);
      return;
    }

    const siteXTransportMachinery: SiteXTransportMachinery = {
      SiteId: site.Id!,
      TransportMachineryId: newTransportMachinery.Id!
    };
    apiService.allocateTransportMachinery(siteXTransportMachinery).then((addedSiteXTransportMachinery: SiteXTransportMachinery) => {
      setIsAddTransportMachineryOpen(false);
      loadTransportMachineries(site.Id!);
    }).catch((reason: any) => {
      setShowErrorMessage(true);
      setErrorMessages([reason.response.data]);
    });
  }

  const deleteTransportMachinery = () => {
    const siteXTransportMachinery: SiteXTransportMachinery = {
      SiteId: site.Id!,
      TransportMachineryId: transportMachinery.Id!
    };
    apiService.deallocateTransportMachinery(siteXTransportMachinery).then(() => {
      setIsDeleteTransportMachineryOpen(false);
      loadTransportMachineries(site.Id!);
    });
  }

  const loadTransportMachineries = (siteId: number) => {
    setIsTransportMachineryLoading(true);
    apiService.loadAllocatedTransportMachineries(siteId, transportMachineryPaginationModel, transportMachinerySortModel, transportMachineryFilterModel, [
      { 
        field: "RentalCompany", 
        mappedField: "RentalCompany/Name" 
      }
    ]).then((odataResponse: ODataResponse<TransportMachinery>) => {
      setTransportMachineries(odataResponse.value);
      setTransportMachineryRowCount(odataResponse.count);
      setIsTransportMachineryLoading(false);
    });

    apiService.loadUnallocatedTransportMachineries(siteId).then((odataResponse: ODataResponse<TransportMachinery>) => {
      setUnallocatedTransportMachineries(odataResponse.value);
    });
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        {selectionModel.length > 0 &&
          <>
            <Item>
              <TooltipButton title='Sterge santier'>
                <Button variant="contained" onClick={openDelete} startIcon={<FontAwesomeIcon icon={faTrashAlt} />}>
                  Sterge
                </Button>
              </TooltipButton>
            </Item>
            <Item>
              <TooltipButton title='Modifica santier'>
                <Button variant="contained" onClick={openEdit} startIcon={<FontAwesomeIcon icon={faEdit} />}>
                  Modifica
                </Button>
              </TooltipButton>
            </Item>
          </>}
        <Item>
          <TooltipButton title='Adauga santier'>
            <Button variant="contained" onClick={openAdd} startIcon={<FontAwesomeIcon icon={faPlusSquare} />}>
              Adauga
            </Button>
          </TooltipButton>
        </Item>
      </Box>
      <Grid rows={sites} columns={columns} selectionModel={selectionModel} onSelectionModelChange={onSetSelectionModelChange} paginationModel={paginationModel} onPaginationModelChange={onPaginationModelChange} onSortModelChange={onSortModelChange} onFilterModelChange={onFilterModelChange} isLoading={isLoading} getRowId={(row) => row.Id} rowCount={rowCount}></Grid>
      <Modal open={isAddOpen} title='Adauga santier' submit={addSite} handleClose={closeAdd}><AddSite onChange={onAddChange} site={newSite} showErrorMessage={showErrorMessage} errorMessages={errorMessages} /></Modal>
      <Modal open={isEditOpen} title='Modifica santier' submit={editSite} handleClose={closeEdit}><AddSite onChange={onEditChange} site={editedSite} showErrorMessage={showErrorMessage} errorMessages={errorMessages} /></Modal>
      <Modal open={isDeleteOpen} title='Sterge santier' submit={deleteSite} handleClose={closeDelete} submitButtonName={'Confirma'}><Typography>Esti sigur ca doresti sa stergi santierul {site.Name} din lista?</Typography></Modal>
      <br />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        {transportMachinerySelectionModel.length > 0 &&
          <>
            <Item>
              <TooltipButton title='Sterge camion'>
                <Button variant="contained" onClick={openDeleteTransportMachinery} startIcon={<FontAwesomeIcon icon={faTrashAlt} />}>
                  Sterge
                </Button>
              </TooltipButton>
            </Item>
          </>}
        {site.Id &&
          <Item>
            <TooltipButton title='Adauga camion'>
              <Button variant="contained" onClick={openAddTransportMachinery} startIcon={<FontAwesomeIcon icon={faPlusSquare} />}>
                Adauga
              </Button>
            </TooltipButton>
          </Item>
        }
      </Box>
      <Grid rows={transportMachineries} columns={transportMachineriesColumns} selectionModel={transportMachinerySelectionModel} onSelectionModelChange={onSetTransportMachinerySelectionModelChange} paginationModel={transportMachineryPaginationModel} onPaginationModelChange={onTransportMachineryPaginationModelChange} onSortModelChange={onTransportMachinerySortModelChange} onFilterModelChange={onTransportMachineryFilterModelChange} isLoading={isTransportMachineryLoading} getRowId={(row) => row.Id} rowCount={transportMachineryRowCount}></Grid>
      <Modal open={isAddTransportMachineryOpen} title='Adauga camion' submit={addTransportMachinery} handleClose={closeAddTransportMachinery}><AllocateTransportMachinery onChange={onAddTransportMachineryChange} transportMachinery={newTransportMachinery} transportMachineries={unallocatedTransportMachineries} showErrorMessage={showErrorMessage} errorMessages={errorMessages} /></Modal>
      <Modal open={isDeleteTransportMachineryOpen} title='Sterge camion' submit={deleteTransportMachinery} handleClose={closeDeleteTransportMachinery} submitButtonName={'Confirma'}><Typography>Esti sigur ca doresti sa stergi camionul cu numarul {transportMachinery.LicensePlateNumber} din lista?</Typography></Modal>
    </Box>
  );
}
