class StringUtils {
    static isNullOrWhiteSpace(input: string | undefined) {
        if (typeof input === 'undefined' || input === null) {
            return true;
        }
        if (typeof input !== 'string') {
            return false;
        }

        return input.replace(/\s/g, '').length < 1;
    }

    static getLength(input: string | undefined) {
        if (typeof input === 'undefined' || input === null) {
            return 0;
        }
        if (typeof input !== 'string') {
            return 0;
        }

        return input.length;
    }
}

export default StringUtils;