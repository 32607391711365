import { User } from "../../interfaces/User";
import ValidationModel from "../../services/ValidationModel";
import ValidationUtils from "../../services/ValidationUtils";

const userNameIsMandatoryMessage = "Numele utilizatorului este obligatoriu!";
const userNameMustBeLowerThan50CharactersMessage = "Numele utilizatorului trebuie sa aiba maximum 50 de caractere!";

export default class UserValidation extends ValidationUtils {
    static validateUser(user: User): ValidationModel {
        const validationModel = {
            validationMessages: [],
            isValid: true
        } as ValidationModel;

        const { userName } = user;

        const isUserNameCompleted = this.validateRequiredString(userName);

        if (!isUserNameCompleted) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(userNameIsMandatoryMessage);
        }

        const isUserNameLengthCorrect = this.validateMaximumStringLength(userName, { maxLength: 50 });

        if (!isUserNameLengthCorrect) {
            validationModel.isValid = false;
            validationModel.validationMessages.push(userNameMustBeLowerThan50CharactersMessage);
        }

        return validationModel;
    }
}