import base64 from 'base-64';

const saveItem = (item: string, value: any) => {
    try {
        const serializeValue = JSON.stringify(value);
        localStorage.setItem(base64.encode(item), base64.encode(serializeValue));
    }
    catch (err) {

    }
}

const getItem = (itemKey: string) => {
    try {
        const item = localStorage.getItem(base64.encode(itemKey));
        if (item != null)
            return JSON.parse(base64.decode(item));
    }
    catch (err) {
        return null;
    }
}

const clearItem = (key: string) => {
    try {
        localStorage.removeItem(base64.encode(key))
    }
    catch (err) { }
}

export default {
    saveItem,
    getItem,
    clearItem
}