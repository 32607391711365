import { GridFilterItem, GridFilterModel, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import axios from "axios";
import { ApiUrl } from "../browser/config";
import localStorageConstants from "../browser/localStorageConstants";
import localStorageUtils from "../browser/localStorageUtils";
import Material from "../interfaces/Material";
import { FilterMap, ODataResponse } from "../interfaces/OData";
import { AuthenticateUser, PasswordReset, User } from "../interfaces/User";
import { RentalCompany } from "../interfaces/RentalCompany";
import Site from "../interfaces/Site";
import LoadingMachineryType from "../interfaces/LoadingMachineryType";
import TransportMachinery from "../interfaces/TransportMachinery";
import LoadedTransportMachinery from "../interfaces/LoadedTransportMachinery";
import SiteXTransportMachinery from "../interfaces/SiteXTransportMachinery";
import dayjs from "dayjs";

const signIn = async (authenticateUser: AuthenticateUser): Promise<Boolean> => {
    try {
        const url = `${ApiUrl}api/User/GenerateToken`;
        const token = await axios.post<string>(url, authenticateUser);
        localStorageUtils.saveItem(localStorageConstants.AUTH_TOKEN, token.data);
        return true;
    } catch (err: any) {
        if (err.response.status === 401)
            return false;
        throw err;
    }
};

const resetPassword = async (passwordReset: PasswordReset): Promise<void> => {
    const url = `${ApiUrl}api/User/ResetPassword`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    await axios.put<PasswordReset>(url, passwordReset, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
}

const loadMaterial = async (paginationModel: GridPaginationModel, sortModel: GridSortModel, filterModel: GridFilterModel): Promise<ODataResponse<Material>> => {
    const url = `${ApiUrl}odata/Material?$count=true${buildODataQuery(paginationModel, sortModel, filterModel)}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const materialEntities = await axios.get<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    const response = materialEntities.data as ODataResponse<Material>;
    response.count = materialEntities.data["@odata.count"];
    return response;
}

const addMaterial = async (material: Material): Promise<Material> => {
    const url = `${ApiUrl}odata/Material`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const newMaterial = await axios.post<Material>(url, material, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    return newMaterial.data;
}

const editMaterial = async (material: Material): Promise<Material> => {
    const url = `${ApiUrl}odata/Material/${material.Id}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const updatedMaterial = await axios.patch<Material>(url, material, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    return updatedMaterial.data;
}

const deleteMaterial = async (id: number): Promise<void> => {
    const url = `${ApiUrl}odata/Material/${id}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    await axios.delete<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

const loadRentalCompanies = async (paginationModel: GridPaginationModel, sortModel: GridSortModel, filterModel: GridFilterModel): Promise<ODataResponse<RentalCompany>> => {
    const url = `${ApiUrl}odata/RentalCompany?$count=true${buildODataQuery(paginationModel, sortModel, filterModel)}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const rentalCompaniesEntities = await axios.get<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    const response = rentalCompaniesEntities.data as ODataResponse<RentalCompany>;
    response.count = rentalCompaniesEntities.data["@odata.count"];
    return response;
}

const loadAllRentalCompanies = async (): Promise<ODataResponse<RentalCompany>> => {
    const url = `${ApiUrl}odata/RentalCompany`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const rentalCompaniesEntities = await axios.get<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    const response = rentalCompaniesEntities.data as ODataResponse<RentalCompany>;
    return response;
}

const addRentalCompany = async (rentalCompany: RentalCompany): Promise<RentalCompany> => {
    const url = `${ApiUrl}odata/RentalCompany`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const newRentalCompany = await axios.post<RentalCompany>(url, rentalCompany, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    return newRentalCompany.data;
}

const editRentalCompany = async (rentalCompany: RentalCompany): Promise<RentalCompany> => {
    const url = `${ApiUrl}odata/RentalCompany/${rentalCompany.Id}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const updatedRentalCompany = await axios.patch<RentalCompany>(url, rentalCompany, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    return updatedRentalCompany.data;
}

const deleteRentalCompany = async (id: number): Promise<void> => {
    const url = `${ApiUrl}odata/RentalCompany/${id}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    await axios.delete<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

const loadLoadingMachineryTypes = async (paginationModel: GridPaginationModel, sortModel: GridSortModel, filterModel: GridFilterModel, fieldMapping: FilterMap[]): Promise<ODataResponse<LoadingMachineryType>> => {
    const url = `${ApiUrl}odata/LoadingMachineryType?$count=true&$expand=RentalCompany${buildODataQuery(paginationModel, sortModel, filterModel, fieldMapping)}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const rentalCompaniesEntities = await axios.get<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    const response = rentalCompaniesEntities.data as ODataResponse<LoadingMachineryType>;
    response.count = rentalCompaniesEntities.data["@odata.count"];
    return response;
}

const addLoadingMachineryType = async (loadingMachineryType: LoadingMachineryType): Promise<LoadingMachineryType> => {
    const url = `${ApiUrl}odata/LoadingMachineryType`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);

    const loadingMachineryTypeEntity = {
        Name: loadingMachineryType?.Name,
        RentalCompanyId: loadingMachineryType?.RentalCompany?.Id ?? null
    } as LoadingMachineryType;

    const newLoadingMachineryType = await axios.post<LoadingMachineryType>(url, loadingMachineryTypeEntity, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    return newLoadingMachineryType.data;
}

const editLoadingMachineryType = async (loadingMachineryType: LoadingMachineryType): Promise<LoadingMachineryType> => {
    const url = `${ApiUrl}odata/LoadingMachineryType/${loadingMachineryType.Id}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);

    const loadingMachineryTypeEntity = {
        Id: loadingMachineryType?.Id,
        Name: loadingMachineryType?.Name,
        RentalCompanyId: loadingMachineryType?.RentalCompany?.Id ?? null
    } as LoadingMachineryType;

    const updatedLoadingMachineryType = await axios.patch<LoadingMachineryType>(url, loadingMachineryTypeEntity, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    return updatedLoadingMachineryType.data;
}

const deleteLoadingMachineryType = async (id: number): Promise<void> => {
    const url = `${ApiUrl}odata/LoadingMachineryType/${id}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    await axios.delete<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

const loadSites = async (paginationModel: GridPaginationModel, sortModel: GridSortModel, filterModel: GridFilterModel): Promise<ODataResponse<Site>> => {
    const url = `${ApiUrl}odata/Site?$count=true${buildODataQuery(paginationModel, sortModel, filterModel)}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const rentalCompaniesEntities = await axios.get<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    const response = rentalCompaniesEntities.data as ODataResponse<Site>;
    response.count = rentalCompaniesEntities.data["@odata.count"];
    return response;
}

const addSite = async (site: Site): Promise<Site> => {
    const url = `${ApiUrl}odata/Site`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const newSite = await axios.post<Site>(url, site, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    return newSite.data;
}

const editSite = async (site: Site): Promise<Site> => {
    const url = `${ApiUrl}odata/Site/${site.Id}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const updatedSite = await axios.patch<Site>(url, site, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    return updatedSite.data;
}

const deleteSite = async (id: number): Promise<void> => {
    const url = `${ApiUrl}odata/Site/${id}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    await axios.delete<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

const loadTransportMachineries = async (paginationModel: GridPaginationModel, sortModel: GridSortModel, filterModel: GridFilterModel, fieldMapping: FilterMap[]): Promise<ODataResponse<TransportMachinery>> => {
    const url = `${ApiUrl}odata/TransportMachinery?$count=true&$expand=RentalCompany${buildODataQuery(paginationModel, sortModel, filterModel, fieldMapping)}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const rentalCompaniesEntities = await axios.get<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    const response = rentalCompaniesEntities.data as ODataResponse<TransportMachinery>;
    response.count = rentalCompaniesEntities.data["@odata.count"];
    return response;
}

const loadAllocatedTransportMachineries = async (siteId: number, paginationModel: GridPaginationModel, sortModel: GridSortModel, filterModel: GridFilterModel, fieldMapping: FilterMap[]): Promise<ODataResponse<TransportMachinery>> => {
    const url = `${ApiUrl}odata/TransportMachinery?$count=true&$expand=SiteXTransportMachineryEntities,RentalCompany&${(filterModel && filterModel.items && filterModel.items.length > 0 && isFilterInitialized(filterModel.items[0])) ? `${buildODataQuery(paginationModel, sortModel, filterModel, fieldMapping)} ${filterModel.logicOperator} SiteXTransportMachineryEntities/any(s: s/SiteId eq ${siteId})` : `${buildODataQuery(paginationModel, sortModel, filterModel, fieldMapping)}&$filter=SiteXTransportMachineryEntities/any(s: s/SiteId eq ${siteId})`}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const rentalCompaniesEntities = await axios.get<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    const response = rentalCompaniesEntities.data as ODataResponse<TransportMachinery>;
    response.count = rentalCompaniesEntities.data["@odata.count"];
    return response;
}

const loadUnallocatedTransportMachineries = async (siteId: number): Promise<ODataResponse<TransportMachinery>> => {
    const url = `${ApiUrl}odata/TransportMachinery?$expand=SiteXTransportMachineryEntities&$filter=SiteXTransportMachineryEntities/all(s: s/SiteId ne ${siteId})`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const rentalCompaniesEntities = await axios.get<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    const response = rentalCompaniesEntities.data as ODataResponse<TransportMachinery>;
    response.count = rentalCompaniesEntities.data["@odata.count"];
    return response;
}

const addTransportMachinery = async (transportMachinery: TransportMachinery): Promise<TransportMachinery> => {
    const url = `${ApiUrl}odata/TransportMachinery`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);

    const transportMachineryEntity = {
        Id: transportMachinery?.Id,
        LicensePlateNumber: transportMachinery?.LicensePlateNumber,
        RentalCompanyId: transportMachinery?.RentalCompany?.Id ?? null
    } as TransportMachinery;

    const newTransportMachinery = await axios.post<TransportMachinery>(url, transportMachineryEntity, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    return newTransportMachinery.data;
}

const editTransportMachinery = async (transportMachinery: TransportMachinery): Promise<TransportMachinery> => {
    const url = `${ApiUrl}odata/TransportMachinery/${transportMachinery.Id}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);

    const transportMachineryEntity = {
        Id: transportMachinery?.Id,
        LicensePlateNumber: transportMachinery?.LicensePlateNumber,
        RentalCompanyId: transportMachinery?.RentalCompany?.Id ?? null
    } as TransportMachinery;

    const updatedTransportMachinery = await axios.patch<TransportMachinery>(url, transportMachineryEntity, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    return updatedTransportMachinery.data;
}

const deleteTransportMachinery = async (id: number): Promise<void> => {
    const url = `${ApiUrl}odata/TransportMachinery/${id}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    await axios.delete<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

const allocateTransportMachinery = async (siteXTransportMachinery: SiteXTransportMachinery): Promise<SiteXTransportMachinery> => {
    const url = `${ApiUrl}odata/SiteXTransportMachinery`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const newSiteXTransportMachinery = await axios.post<SiteXTransportMachinery>(url, siteXTransportMachinery, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    return newSiteXTransportMachinery.data;
}

const deallocateTransportMachinery = async (siteXTransportMachinery: SiteXTransportMachinery): Promise<void> => {
    const url = `${ApiUrl}api/TransportMachinery/Deallocate?siteId=${siteXTransportMachinery.SiteId}&transportMachineryId=${siteXTransportMachinery.TransportMachineryId}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    await axios.delete<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

const loadUsers = async (): Promise<User[]> => {
    const url = `${ApiUrl}api/User/GetUsers`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const users = await axios.get<User[]>(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    return users.data;
}

const addUser = async (user: User): Promise<void> => {
    const url = `${ApiUrl}api/User/CreateUser`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);

    const userEntity = {
        ...user,
        RentalCompanyId: user?.rentalCompany?.id ?? null
    } as User;

    await axios.post<any>(url, userEntity, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
}

const editUser = async (user: User): Promise<void> => {
    const url = `${ApiUrl}api/User/UpdateUser?id=${user.id}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);

    const userEntity = {
        ...user,
        RentalCompanyId: user?.rentalCompany?.id ?? null
    } as User;

    await axios.put<User>(url, userEntity, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
}

const deleteUser = async (id: number): Promise<void> => {
    const url = `${ApiUrl}api/User/DeleteUser?id=${id}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    await axios.delete<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

const loadLoadedTransportMachineries = async (paginationModel: GridPaginationModel, sortModel: GridSortModel, filterModel: GridFilterModel, fieldMapping: FilterMap[], dateFrom: Date | null, dateTo: Date | null): Promise<ODataResponse<LoadedTransportMachinery>> => {
    let dateFilter = "";
    if ((dateFrom || dateTo) && (filterModel && filterModel.items && filterModel.items.length > 0 && isFilterInitialized(filterModel.items[0]))) {
        dateFilter = `${filterModel.logicOperator ?? "and"} `;
    }
    if (dateFrom) {
        let alignedDate = new Date(dateFrom);
        var timeOffsetInMS: number = alignedDate.getTimezoneOffset() * 60000;
        alignedDate.setTime(alignedDate.getTime() + 2 * timeOffsetInMS);
        dateFilter = `${dateFilter}LoadedAt ge ${dayjs(alignedDate).format('YYYY-MM-DDTHH:mm:ss[Z]')}`;
    }
    if (dateTo) {
        if (dateFrom) {
            dateFilter = `${dateFilter} ${filterModel.logicOperator ?? "and"} `;
        }
        let alignedDate = new Date(dateTo);
        var timeOffsetInMS: number = alignedDate.getTimezoneOffset() * 60000;
        alignedDate.setTime(alignedDate.getTime() + 2 * timeOffsetInMS);
        dateFilter = `${dateFilter}LoadedAt le ${dayjs(alignedDate).format('YYYY-MM-DDTHH:mm:ss[Z]')}`;
    }
    const url = `${ApiUrl}odata/LoadedTransportMachinery?$count=true&$expand=TransportMachinery,LoadedBy,Site,Material,LoadingMachineryType&${(filterModel && filterModel.items && filterModel.items.length > 0 && isFilterInitialized(filterModel.items[0])) ? `${buildODataQuery(paginationModel, sortModel, filterModel, fieldMapping)} ${dateFilter}` : `${buildODataQuery(paginationModel, sortModel, filterModel, fieldMapping)}${dateFilter ? `&$filter=${dateFilter}` : ""}`}`;
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const loadedTransportMachineries = await axios.get<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }
    });
    const response = loadedTransportMachineries.data as ODataResponse<LoadedTransportMachinery>;
    response.count = loadedTransportMachineries.data["@odata.count"];
    return response;
}

const exportLoadedTransportMachineries = async (sortModel: GridSortModel, filterModel: GridFilterModel, fieldMapping: FilterMap[], dateFrom: Date | null, dateTo: Date | null): Promise<Blob> => {
    let dateFilter = "";
    if ((dateFrom || dateTo) && (filterModel && filterModel.items && filterModel.items.length > 0 && isFilterInitialized(filterModel.items[0]))) {
        dateFilter = `${filterModel.logicOperator ?? "and"} `;
    }
    if (dateFrom) {
        let alignedDate = new Date(dateFrom);
        var timeOffsetInMS: number = alignedDate.getTimezoneOffset() * 60000;
        alignedDate.setTime(alignedDate.getTime() + 2 * timeOffsetInMS);
        dateFilter = `${dateFilter}LoadedAt ge ${dayjs(alignedDate).format('YYYY-MM-DDTHH:mm:ss[Z]')}`;
    }
    if (dateTo) {
        if (dateFrom) {
            dateFilter = `${dateFilter} ${filterModel.logicOperator ?? "and"} `;
        }
        let alignedDate = new Date(dateTo);
        var timeOffsetInMS: number = alignedDate.getTimezoneOffset() * 60000;
        alignedDate.setTime(alignedDate.getTime() + 2 * timeOffsetInMS);
        dateFilter = `${dateFilter}LoadedAt le ${dayjs(alignedDate).format('YYYY-MM-DDTHH:mm:ss[Z]')}`;
    }
    const url = `${ApiUrl}api/LoadedTransportMachinery/Export?$expand=TransportMachinery,LoadedBy,Site,Material,LoadingMachineryType&${(filterModel && filterModel.items && filterModel.items.length > 0 && isFilterInitialized(filterModel.items[0])) ? `${buildODataQuery(undefined, sortModel, filterModel, fieldMapping)} ${dateFilter}` : `${buildODataQuery(undefined, sortModel, filterModel, fieldMapping)}${dateFilter ? `&$filter=${dateFilter}` : ""}`}`;
    console.log(url);
    const token = localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN);
    const loadedTransportMachineriesExcel = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'blob'
    });
    return loadedTransportMachineriesExcel.data;
}

const buildODataQuery = (paginationModel: GridPaginationModel | undefined, sortModel: GridSortModel, filterModel: GridFilterModel, fieldMapping: FilterMap[] = []): string => {
    let query = "";
    if (paginationModel) {
        query = `&$top=${paginationModel.pageSize}&$skip=${(paginationModel.page) * paginationModel.pageSize}`;
    }
    if (sortModel && sortModel.length > 0) {
        query = `${query}&$orderby=`;
        for (let sortIndex = 0; sortIndex < sortModel.length; sortIndex++) {
            let field = sortModel[sortIndex].field;
            const fieldMap = fieldMapping.find(fieldMapEntry => fieldMapEntry.field === field);
            if (fieldMap) {
                field = fieldMap.mappedField;
            }

            query = `${query}${field} ${sortModel[sortIndex].sort}`;
            if (sortIndex !== sortModel.length - 1) {
                query = `${query}, `;
            }
        }
    }
    if (filterModel && filterModel.items && filterModel.items.length > 0 && isFilterInitialized(filterModel.items[0])) {
        query = `${query}&$filter=`;
        for (let filterIndex = 0; filterIndex < filterModel.items.length; filterIndex++) {
            if (!isFilterInitialized(filterModel.items[filterIndex]))
                continue;
            const odataOperator = convertToODataFilterOperator(filterModel.items[filterIndex].operator);
            let field = filterModel.items[filterIndex].field;
            const fieldMap = fieldMapping.find(fieldMapEntry => fieldMapEntry.field === field);
            if (fieldMap) {
                field = fieldMap.mappedField;
            }
            switch (odataOperator) {
                case "contains":
                case "startswith":
                case "endswith":
                    query = `${query}${odataOperator}(cast(${field}, 'Edm.String'),'${filterModel.items[filterIndex].value}')`;
                    break;
                case "isEmpty":
                    query = `${query}cast(${field}, 'Edm.String') eq ''`;
                    break;
                case "isNotEmpty":
                    query = `${query}cast(${field}, 'Edm.String') ne ''`;
                    break;
                case "in":
                    query = `${query}cast(${field}, 'Edm.String') ${odataOperator} (`;
                    for (let inIndex = 0; inIndex < filterModel.items[filterIndex].value.length; inIndex++) {
                        query = `${query}'${filterModel.items[filterIndex].value[inIndex]}'`;
                        if (inIndex !== filterModel.items[filterIndex].value.length - 1) {
                            query = `${query}, `;
                        }
                    }
                    query = `${query})`;
                    break;
                default:
                    query = `${query}${field} ${odataOperator} ${filterModel.items[filterIndex].value}`;
                    break;
            }
            if (filterIndex !== filterModel.items.length - 1) {
                query = `${query} ${filterModel.logicOperator} `;
            }
        }
    }
    return query;
}

const isFilterInitialized = (filter: GridFilterItem): boolean => {
    switch (filter.operator) {
        case "isEmpty":
        case "isNotEmpty":
            return true;
        default:
            return filter.value;
    }
}

const convertToODataFilterOperator = (gridFilterOperator: string): string => {
    switch (gridFilterOperator) {
        case "contains":
            return "contains";
        case "equals":
            return "eq";
        case "startsWith":
            return "startswith";
        case "endsWith":
            return "endswith";
        case "isAnyOf":
            return "in";
        default:
            return gridFilterOperator;
    }
}

export default {
    signIn,
    resetPassword,
    loadMaterial,
    addMaterial,
    editMaterial,
    deleteMaterial,
    loadRentalCompanies,
    loadAllRentalCompanies,
    addRentalCompany,
    editRentalCompany,
    deleteRentalCompany,
    loadLoadingMachineryTypes,
    addLoadingMachineryType,
    editLoadingMachineryType,
    deleteLoadingMachineryType,
    loadSites,
    addSite,
    editSite,
    deleteSite,
    loadTransportMachineries,
    loadAllocatedTransportMachineries,
    loadUnallocatedTransportMachineries,
    addTransportMachinery,
    editTransportMachinery,
    deleteTransportMachinery,
    allocateTransportMachinery,
    deallocateTransportMachinery,
    loadUsers,
    addUser,
    editUser,
    deleteUser,
    loadLoadedTransportMachineries,
    exportLoadedTransportMachineries
};