import './App.css';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from './components/Home';
import SignIn from './components/login/SignIn';
import LeftSideBar from './components/LeftSideBar';
import PrivateRoute from './components/login/PrivateRoute';
import MaterialEntities from './components/material/MaterialEntities';
import RentalCompanies from './components/rentalCompany/RentalCompanies';
import LoadingMachineryTypes from './components/loadingMachineryType/LoadingMachineryTypes';
import Sites from './components/site/Sites';
import TransportMachineries from './components/transportMachinery/TransportMachineries';
import Users from './components/user/Users';
import LoadedTransportMachineries from './components/loadedTransportMachinery/LoadedTransportMachineries';
import React from 'react';
import localStorageUtils from './browser/localStorageUtils';
import localStorageConstants from './browser/localStorageConstants';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function App() {
  const [loggedIn, setLoggedIn] = React.useState(localStorageUtils.getItem(localStorageConstants.AUTH_TOKEN) !== undefined);
  return (
    <div className="App">
      {
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <LeftSideBar loggedIn={loggedIn} setLoggedIn={setLoggedIn}>
            <Switch>
              <Route exact path='/'>
                <Redirect
                  to="/loadedTransportMachinery"
                />
              </Route>
              <Route path='/signIn' render={(props) => <SignIn setLoggedIn={setLoggedIn} />} />
              <Route path='/material'>
                <PrivateRoute> <MaterialEntities /></PrivateRoute>
              </Route>
              <Route path='/rentalCompany'>
                <PrivateRoute> <RentalCompanies /></PrivateRoute>
              </Route>
              <Route path='/loadingMachineryType'>
                <PrivateRoute> <LoadingMachineryTypes /></PrivateRoute>
              </Route>
              <Route path='/Site'>
                <PrivateRoute> <Sites /></PrivateRoute>
              </Route>
              <Route path='/transportMachinery'>
                <PrivateRoute> <TransportMachineries /></PrivateRoute>
              </Route>
              <Route path='/user'>
                <PrivateRoute> <Users /></PrivateRoute>
              </Route>
              <Route path='/loadedTransportMachinery'>
                <PrivateRoute> <LoadedTransportMachineries /></PrivateRoute>
              </Route>
            </Switch>
          </LeftSideBar>
        </LocalizationProvider>
      }
    </div>
  );
}

export default App;
